import React, { useState, useRef } from "react";
import { Img, Text, Button, Input } from "components";
import { getAuthUrl, getImidServiceUrl } from "utils/api-util";
import axios from "axios";
import ClipLoader from "react-spinners/ClipLoader";

// import ImageIcon from "../../assets/images/imageIcon/img_group8_gray_51.svg";
import "./CustomerFormWrapper.css";
import CustomerCreation from "modals/CustomerCreation";
import { useNavigate } from "react-router-dom";

const CreateCustomerForm = () => {
  const [fileName, setFileName] = useState("");
  const fileInputRef = useRef(null);

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [province, setProvince] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [customerType, setCustomerType] = useState("ENERGY_COMPANY");
  const [error, setError] = useState("");
  const [isOpen, setIsSubmittedSuccessfully] = useState(false)


  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);

  const baseUrl = getAuthUrl();

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFileName(selectedFile.name);
  };

  const onClose = () => {
    setIsSubmittedSuccessfully(false)
    navigate('/imidcustomer')
  }

  const validatePhoneNumber = (number) => {
    // Regular expression for validating international phone numbers (E.164 format)
    const phoneRegEx = /^\+?[1-9]\d{1,14}$/;  // Matches numbers with a + and up to 15 digits

    if (phoneRegEx.test(number)) {
      setError(''); // Clear error if valid
      return true;
    } else {
      setError('Please enter a valid international phone number (e.g., +1234567890).');
      return false;
    }
  };

  const validateEmail = (email) => {

    // Regular expression for basic email validation
    const emailRegEx = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (emailRegEx.test(email)) {
      setError(''); // Clear error if valid
      return true;
    } else {
      setError('Please enter a valid email address.');
      return false;
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setPhoneNumber(value);
    validatePhoneNumber(value);
    validateEmail(value)
  };

  const token = window.localStorage.getItem("token");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const url =
      `${baseUrl}/customer`;

    const data = {
      name,
      phoneNumber,
      emailAddress,
      city,
      province,
      postalCode,
      country,
      customerType,
      address,
    };
    const headers = {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${token}`,
    };

    try {
      setError('')
      if (name === '' || phoneNumber === '' || emailAddress === '' || city === '' || province === '' || postalCode === '' || country === '' || address === '') {
        setError('Please fill all inputs correctly')
        setIsSubmittedSuccessfully(false)
        return
      }
      const res = await axios.post(url, data, { headers });

      console.log(res)
      if (res.status === 202) {
        setIsSubmittedSuccessfully(true)
        setName("");
        setPhoneNumber("");
        setEmailAddress("");
        setCity("");
        setProvince("");
        setPostalCode("");
        setCountry("");
        setCustomerType(customerType);
        setAddress("");
      } else {
        setError("Error sending Data");
        setIsSubmittedSuccessfully(false)
      }
    } catch (err) {
      console.log(err);
      setError(err.message)
    } finally {
      setLoading(false); // Reset loading to false when login action is completed

    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <CustomerCreation isOpen={isOpen} onClose={onClose} />
        <div className="customer-form-wrapper">
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="user-name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              labelText="Company/Customer Name"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-blue-500 peer-focus:dark:text-blue-500"
              name="emailaddress"
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
              labelText="Email Address"
              required={true}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="phonenumber"
              value={phoneNumber}
              maxLength={11}
              onChange={handleChange}
              type="number"
              labelText="Phone Number"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              labelText="Address"
            ></Input>

            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="City"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              labelText="City"
            ></Input>


          </div>
          <div>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="province"
              value={province}
              onChange={(e) => setProvince(e.target.value)}
              labelText="Province"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Zip Code"
              value={postalCode}
              type="number"
              onChange={(e) => setPostalCode(e.target.value)}
              labelText="Zip Code"
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Country"
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              labelText="Country"
            ></Input>
            {/*<Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-full"
              className="font-semibold p-[0] placeholder:text-gray_600 text-[16px] text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
              name="Customer Type"
              value={customerType}
              onChange={(e) => setCustomerType(e.target.value)}
              labelText="Customer Type"
            ></Input>*/}
            <div className=" w-full " >
              <select value={customerType} onChange={(e) => setCustomerType(e.target.value)} className='w-full text-sm bg-gray_100 border-[1px] text-[16px] text-gray_600 font-semibold outline-none border-light_blue_800 font-montserrat h-[66px] md:h-[auto] mt-[25px] rounded-[10px] py-[11px]' >
                <option value='ENERGY_COMPANY' >Energy Company</option>
                <option value='PROPERTY_MANAGER' >Property Manager</option>
                <option value='HOME_OWNER' >Home Owner</option>
              </select>
            </div>
          </div>
          <p className='text-[red] text-[12px]' >{error}</p>
        </div>
        <Button type='submit' className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
          {loading ? (
            <ClipLoader size={20} color={"#ffffff"} />
          ) : (
            "Add Customer"
          )}
        </Button>
      </form>
    </>
  );
};

export default CreateCustomerForm;
