import React from "react";
import "./customerDetails.styles.css"
export const CustomerDetailIcon = () => {
  return (
    <>
      <div className="customer-details-wrapper">
        <div
          style={{
            width: 200,
            height: 180,
            background: "white",
            borderRadius: 10,
            border: "0.20px #F7FAFD solid",
          }}
        >
          <div
            style={{
              width: 106,
              height: 117,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 20,
              display: "inline-flex",
            }}
          >
            <div style={{ width: 40, height: 40, position: "relative" }}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  left: 0,
                  top: 0,
                  position: "absolute",
                  background: "#F7FAFD",
                  borderRadius: 9999,
                  border: "0.10px #F7FAFD solid",
                }}
              />
              <div
                style={{
                  width: 20,
                  height: 20,
                  left: 10,
                  top: 10,
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    left: 0,
                    top: 0,
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                      position: "absolute",
                      opacity: 0,
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.82,
                      height: 4.81,
                      left: 12.12,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.47,
                      height: 4.66,
                      left: 13.83,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.81,
                      height: 4.81,
                      left: 3.05,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.32,
                      height: 4.67,
                      left: 1.66,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 5.74,
                      height: 5.73,
                      left: 7.12,
                      top: 7.5,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 7.1,
                      height: 4.5,
                      left: 6.45,
                      top: 14.23,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#0074BB",
              fontSize: 24,
              fontFamily: "Montserrat",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            15
          </div>
          <div
            style={{
              color: "#828282",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Total Customers
          </div>
        </div>

        <div
          style={{
            width: 200,
            height: 180,
            background: "white",
            borderRadius: 10,
            border: "0.20px #F7FAFD solid",
          }}
        >
          <div
            style={{
              width: 106,
              height: 117,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 20,
              display: "inline-flex",
            }}
          >
            <div style={{ width: 40, height: 40, position: "relative" }}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  left: 0,
                  top: 0,
                  position: "absolute",
                  background: "#F7FAFD",
                  borderRadius: 9999,
                  border: "0.10px #F7FAFD solid",
                }}
              />
              <div
                style={{
                  width: 20,
                  height: 20,
                  left: 10,
                  top: 10,
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    left: 0,
                    top: 0,
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                      position: "absolute",
                      opacity: 0,
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.82,
                      height: 4.81,
                      left: 12.12,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.47,
                      height: 4.66,
                      left: 13.83,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.81,
                      height: 4.81,
                      left: 3.05,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.32,
                      height: 4.67,
                      left: 1.66,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 5.74,
                      height: 5.73,
                      left: 7.12,
                      top: 7.5,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 7.1,
                      height: 4.5,
                      left: 6.45,
                      top: 14.23,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#0074BB",
              fontSize: 24,
              fontFamily: "Montserrat",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            15
          </div>
          <div
            style={{
              color: "#828282",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Active Customers
          </div>
        </div>

        <div
          style={{
            width: 200,
            height: 180,
            background: "white",
            borderRadius: 10,
            border: "0.20px #F7FAFD solid",
          }}
        >
          <div
            style={{
              width: 106,
              height: 117,
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              gap: 20,
              display: "inline-flex",
            }}
          >
            <div style={{ width: 40, height: 40, position: "relative" }}>
              <div
                style={{
                  width: 40,
                  height: 40,
                  left: 0,
                  top: 0,
                  position: "absolute",
                  background: "#F7FAFD",
                  borderRadius: 9999,
                  border: "0.10px #F7FAFD solid",
                }}
              />
              <div
                style={{
                  width: 20,
                  height: 20,
                  left: 10,
                  top: 10,
                  position: "absolute",
                }}
              >
                <div
                  style={{
                    width: 20,
                    height: 20,
                    left: 0,
                    top: 0,
                    position: "absolute",
                  }}
                >
                  <div
                    style={{
                      width: 20,
                      height: 20,
                      left: 0,
                      top: 0,
                      position: "absolute",
                      opacity: 0,
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.82,
                      height: 4.81,
                      left: 12.12,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.47,
                      height: 4.66,
                      left: 13.83,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                      border: "1px #0074BB solid",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.81,
                      height: 4.81,
                      left: 3.05,
                      top: 1.67,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 4.32,
                      height: 4.67,
                      left: 1.66,
                      top: 8.34,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 5.74,
                      height: 5.73,
                      left: 7.12,
                      top: 7.5,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                  <div
                    style={{
                      width: 7.1,
                      height: 4.5,
                      left: 6.45,
                      top: 14.23,
                      position: "absolute",
                      background: "#0074BB",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "#0074BB",
              fontSize: 24,
              fontFamily: "Montserrat",
              fontWeight: "700",
              wordWrap: "break-word",
            }}
          >
            15
          </div>
          <div
            style={{
              color: "#828282",
              fontSize: 12,
              fontFamily: "Montserrat",
              fontWeight: "400",
              wordWrap: "break-word",
            }}
          >
            Inactive Customers
          </div>
        </div>
      </div>
    </>
  );
};
