import { useState } from "react";
import { Text, Img, Button } from "components";
import PropTypes from "prop-types";
import ProfileSettingsSevenModal from "modals/ProfileSettingsSeven";
import { NavLink, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/authAction";
import { useDispatch } from "react-redux";
import ConfirmLogoutModal from "modals/ConfirmLogout/ConfirmLogoutModal";

const ProfileSettingsSidebarmenu = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  
  const openModal = () => {
    setIsOpen(true)
  }



  const closeModal = () => {
    setIsOpen(false);
  };

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <>
      <aside className={props.className}>
        <div className="flex flex-col gap-[56px] items-start justify-start mb-[318px] mt-[118px] mx-[36px] w-[71%]">
          <Text
            className="font-montserrat font-semibold text-gray_800 text-left w-[auto]"
            variant="body3"
          >
            MENU
          </Text>
          <div className="flex flex-col gap-[55px] items-start justify-start self-stretch w-[auto]">
            {props?.items.map((e, index) => (
              <div key={index} className="flex flex-row gap-[20px] h-[24px] md:h-[auto] items-start justify-start self-stretch w-[auto]">
                <Img src={e.src} className="h-[24px] w-[24px]" alt="icon" />
                <NavLink
                  className={({ isActive }) =>
                    isActive
                      ? "font-medium font-montserrat text-left w-[auto] hover:text-blue-500 text-blue-500"
                      : "font-medium font-montserrat text-gray_301 text-left w-[auto] hover:text-blue-500"
                  }
                  variant="body2"
                  to={e.path}
                >
                  {e.menu}
                </NavLink>
              </div>
            ))}

          </div>
          <div className="flex flex-col gap-[55px] items-start justify-start self-stretch w-[auto]">
            <div className="flex flex-row gap-[20px] h-[24px] md:h-[auto] items-start justify-start self-stretch w-[auto]">
              <Img
                src="images/img_arrowright.svg"
                className="h-[24px] w-[24px]"
                alt="icon"
              />
              <Button
                className="font-medium font-montserrat text-gray_301 text-left w-[auto]"
                variant="body2"
                onClick={openModal}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
        <ConfirmLogoutModal 
        isOpen={isOpen}
        onClose={closeModal}
        onConfirm={handleLogout} />
        <ProfileSettingsSevenModal
        // isOpened={isOpen}
        // closeAction={closeModal}
        // handleLogout={handleLogout}
        />
      </aside>
    </>
  );
};

ProfileSettingsSidebarmenu.defaultProps = {
  items: [],
};

ProfileSettingsSidebarmenu.propTypes = {
  items: PropTypes.array,
};

export default ProfileSettingsSidebarmenu;
