import React, { useEffect, useState } from "react";
import "./buildingDetails.styles.css";
import { useParams, Link, useLocation } from "react-router-dom";
import { Line, Text, List, Img, Button, CheckBox } from "components";
import ElectricityIcon from "../../assets/images/imageIcon/img_ellipse146.png";
import GasIcon from "../../assets/images/imageIcon/img_ellipse146_32x32.png";
import ColdWaterIcon from "../../assets/images/imageIcon/img_ellipse146_1.png";
import HotWaterIcon from "../../assets/images/imageIcon/img_ellipse146_2.png";
import HeatingIcon from "../../assets/images/imageIcon/img_ellipse146_3.png";
import EVChargingIcon from "../../assets/images/imageIcon/img_ellipse146_4.png";
import singleBuildingImage from "../../assets/images/images/single-building.svg";
import buildingImage from '../../assets/images/imageIcon/buildings-2.svg'
import multiBuildingImage from "../../assets/images/images/multi-building.svg";
import { useSelector } from "react-redux";
import BackLogoIconComponent from "components/BackLogoIcon/BackLogoIconComponent";
import GenerateBillModal from "modals/GenerateBillModal";
import AllocateCommonArea from "modals/AllocateCommonArea/AllocateCommonArea";
import AllocateCommonAreaSuccess from "modals/AllocateCommonAreaSuccess/AllocateCommonAreaSuccess";
import { getImidServiceUrl } from "utils/api-util";

function BuildingDetailComponent() {
  const { id } = useParams();
  const [buildings, setBuildings] = useState([]);
  const token = window.localStorage.getItem("token");

  const user = useSelector((state) => state.auth.userData);

  const [isOpen, setIsOpen] = useState(false)
  const [isAllocateCommonAreaOpen, setIsAllocateCommonAreaOpen] = useState(false)
  const [isAllocationSuccessful, setIsAllocationSuccessful] = useState(false)

  const [billingMonth, setBillingMonth] = useState('JANUARY')

  const selectMonth = (month) => {
    setBillingMonth(month)
  }

  // Open Generate BILL modal 
  const openGenerateModal = () => {
    setIsOpen(true)
  }

  const onClose = () => {
    setIsOpen(false)
  }

  // Open Common Area Allocation Modal
  const onConfirm = () => {
    setIsAllocateCommonAreaOpen(true)
    onClose()
  }

  const closeAllocateCommonAreaModal = () => {
    setIsAllocateCommonAreaOpen(false)
    onClose()
  }

  // Open Common Area Allocation Succes Modal

  const openSuccessModal = () => {
    closeAllocateCommonAreaModal()
    setIsAllocationSuccessful(true)
  }

  const closeSuccessModal = () => {
    setIsAllocationSuccessful(false)
  }


  const { state } = useLocation();

  const baseUrl = getImidServiceUrl();

  const customerId = state?.customerId

  const queryParam = id ? `?customerId=${customerId}` : "";

  useEffect(() => {
    const url = `${baseUrl}/building/${id}/apartments${queryParam}`;
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    fetch(url, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setBuildings(data);
      })
      .catch((error) => {
        console.error("There was an error fetching the buildings!", error);
      });
  }, [id, token]);

  return (
    // <div className="mb-36">
    <div className='relative' style={{ width: "100%" }}>
      <div className="mb-3">
        <Link to="/buildings">
          <BackLogoIconComponent />
        </Link>
      </div>
      <div className='flex  mt-10 w-full apartmentHeader justify-between items-center' >
        <h1 className='font-extrabold apartmentHeaderText text-[24px]' >Building Details</h1>
        {user?.role === 'ROLE_SUPER_ADMIN' && <div className='flex apartmentHeaderBtn' >
          <Button onClick={openGenerateModal} className="bg-light_blue_800 flex justify-center items-center cursor-pointer font-extrabold h-[64px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
            <Img
              src={buildingImage}
              className="h-[20px] mr-1 rounded-[50%] w-[20px]"
              alt="ellipse146"
            />
            Generate Bill
          </Button>
        </div>}
      </div>
      <div className="mt-4">
        <div className='my-7'>
          <p className="text-left font-bold text-[16px] pb-1"> Customer Name : {state?.contactName}
          </p>
          <p className="text-left font-bold text-[12px] pb-1"> Unit Number:
            {state?.numberOfApartments}
          </p>

        </div>
        <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-1 md:justify-items-center">
          <div className="md:pb-9">
            <div className="building">
              <center>
                <img
                  alt="single building"
                  src={
                    state.numberOfApartments <= 5
                      ? singleBuildingImage
                      : multiBuildingImage
                  }
                />
              </center>

              <div className="flex justify-between gap-4">
                <div className="building-details">
                  <p className="text-left font-bold text-[10px] w-[100%]  pb-1">
                    Building ID
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">
                    Address
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">City</p>
                  <p className="text-left font-bold text-[10px] w-[100%]  pb-1">
                    Number of Unit
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">
                    Contact{" "}
                  </p>
                </div>
                <div>
                  <p className="text-left font-bold text-[10px] pb-1">
                    {state.id}
                  </p>
                  <p className="text-left font-bold text-[10px] w-[50%]  pb-1">
                    {state.address}
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">
                    {state.city}
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">
                    {state.numberOfApartments}
                  </p>
                  <p className="text-left font-bold text-[10px] pb-1">
                    {state.contactName}
                  </p>
                </div>
              </div>
              <br />
              {/* <Link
                to={{
                  pathname: `/building/${state.id}/info`,
                }}
                state={state}
              >
                <center>
                  <div className="view-building-button">
                    <Button>Building Information</Button>
                  </div>
                </center>
              </Link> */}
            </div>
          </div>
          <div
            className="bg-white_A700 h-[500px] w-[400px] border-[1px]  border-bluegray_600 
              border-solid flex flex-col font-montserrat relative gap-[10px] items-start justify-end p-[10px] 
              rounded-[10px] md:w-[65%] sm:w-[100%] min-w-[150px]"
          >
            <div className="flex flex-row gap-[23px] items-center justify-start md:ml-[0] ml-[17px] mr-[auto] mb-[18px] self-stretch w-[auto]">
              <Line className="bg-light_blue_800 h-[40px] w-[3px]" />
              <div className="flex flex-col gap-[-2px] items-start justify-center self-stretch w-[auto]">
                <Text
                  className="font-bold text-left text-light_blue_800 w-[auto]"
                  variant="body3"
                >
                  Utility Usage
                </Text>
              </div>
            </div>
            <List
              className="flex-col gap-[20px] grid items-start md:ml-[0] ml-[13px] self-stretch w-[auto]"
              orientation="vertical"
            >
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={ElectricityIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      Electricity
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={GasIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      Gas
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={ColdWaterIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      Cold Water
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={HotWaterIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      Hot Water
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={HeatingIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      Heating
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
              <div className="flex flex-col gap-[12px] items-center justify-start w-[100%]">
                <div className="flex flex-row items-end justify-between w-[100%]">
                  <div className="flex flex-row font-montserrat gap-[15px] items-center justify-start self-stretch w-[auto]">
                    <Img
                      src={EVChargingIcon}
                      className="h-[32px] md:h-[auto] rounded-[50%] w-[32px]"
                      alt="ellipse146"
                    />
                    <Text
                      className="font-semibold text-bluegray_900 text-justify w-[auto]"
                      variant="body3"
                    >
                      EV Charging
                    </Text>
                  </div>
                  <Text
                    className="font-bold font-manrope mb-[4px] mt-[10px] text-bluegray_900 text-justify w-[auto]"
                    variant="body4"
                  >
                    -
                  </Text>
                </div>
                <Line className="bg-blue_100 h-[1px] w-[100%]" />
              </div>
            </List>
          </div>
        </div>

        <div className="mt-20 appartment-table bg-clip-border p-3 bg-white">
          <div>
            <p className="font-bold pb-3">Building Unit Information</p>
          </div>
          <div
            className="table-container"
            style={{ overflowX: "auto", width: "100%" }}
          >
            <table className="table-auto">
              <thead>
                <tr>
                  {/* <th style={{backgroundColor:"#0074BB", color:"#fff"}} className="font-bold p-3 text-xs">S/N</th> */}
                  {/* <th className="font-bold">ID</th> */}
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Unit No
                  </th>
                  {/* <th className="font-bold">Customer ID</th> */}
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Tenant Names
                  </th>
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Meter No
                  </th>
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    cumulated KwH
                  </th>

                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    This month KwH
                  </th>
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    Account Status
                  </th>
                  <th
                    style={{ backgroundColor: "#0074BB", color: "#fff" }}
                    className="font-bold p-3 text-xs"
                  >
                    View
                  </th>
                </tr>
              </thead>
              <tbody>
                {buildings
                  .sort((a, b) => a.apartmentNumber - b.apartmentNumber)
                  .map((apartment, index) => (
                    <tr key={apartment.id}>
                      {/* <td className="p-3 text-xs"> {index + 1}</td> */}
                      {/* <td className="p-5"> {building.id}</td> */}
                      <td className="p-3 text-xs">
                        {" "}
                        {apartment.apartmentNumber}
                      </td>
                      {/* <td className="p-5"> {building.customerId}</td> */}
                      <td className="p-3 text-xs"> {apartment.name}</td>
                      <td className="p-3 text-xs"> {apartment.meterNumber}</td>
                      <td className="p-3 text-xs"> {apartment.cumulatedKwH}</td>
                      <td className="p-3 text-xs"> {apartment.monthKwH}</td>
                      <td className="p-3 text-xs"> {apartment.status}</td>
                      <td className="p-3 text-xs">
                        <Link to={`/tenant/charts/${apartment.meterNumber}`}>
                          View
                        </Link>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <GenerateBillModal selectMonth={selectMonth} buildings={buildings} numberOfUnit={state.numberOfApartments} onConfirm={onConfirm} isOpen={isOpen} onClose={onClose} />
      <AllocateCommonArea billingMonth={billingMonth} buildings={buildings} numberOfUnit={state.numberOfApartments} openModal={openSuccessModal} isOpen={isAllocateCommonAreaOpen} onClose={closeAllocateCommonAreaModal} />
      <AllocateCommonAreaSuccess isOpen={isAllocationSuccessful} onClose={closeSuccessModal} />
    </div>
  );
}

export default BuildingDetailComponent;
