import { setUser, setLoading, setError, clearUser, setToken, setRefreshToken, setAuthInfo } from './authReducer';

import { getAuthUrl, getImidServiceUrl } from 'utils/api-util';

export const loginUser = (userData) => async (dispatch) => {
  dispatch(setLoading(true));

  const baseUrl = getAuthUrl();

  console.log("Base url: " + baseUrl);

  const url = `${baseUrl}/auth/login`


  try {
    // Make an API request to the login endpoint
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    console.log(data)

    if (response.status === 200) {
      const expirationTime = new Date().getTime() + data.authenticationData.expiresInSecs * 1000; // Assuming expiresIn is in seconds
      console.log("expiring time ", expirationTime)

      localStorage.setItem('token', data.authenticationData.idToken);
      localStorage.setItem('accessToken', data.authenticationData.accessToken);
      localStorage.setItem('refreshToken', data.authenticationData.refreshToken);
      localStorage.setItem('tokenExpiration', expirationTime);

      dispatch(setToken(data.authenticationData.idToken));
      dispatch(setRefreshToken(data.authenticationData.refreshToken));
      dispatch(setUser(data.userData));
      dispatch(setAuthInfo(data))
    } else {
      dispatch(setError(data.message));
      console.log("error message ")
    }
  } catch (error) {
    dispatch(setError(''));
  } finally {
    dispatch(setLoading(false));
  }
};


export const logoutUser = () => (dispatch) => {
  // Implement logout logic, e.g., clearing tokens, etc.
  // Then, clear the user from the store.
  dispatch(clearUser());
  localStorage.removeItem("token")
  // localStorage.removeItem("refreshToken")
};

export const paymentAuth = async () => {
  console.log("Payment Auth");

  const baseUrl = getImidServiceUrl();
  const token = window.localStorage.getItem("token")
  const url = `${baseUrl}/auth/quickbook`;

  const headers = {
    // Add any headers you need here
    'Content-Type': 'application/json',
    // Example header:
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify({})
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const jsonData = await response.json();

    console.log(jsonData)
    window.location.href = jsonData.redirectUrl;

  } catch (error) {
    console.error('Error:', error);
  }
}

