import BackLogoIconComponent from "components/BackLogoIcon/BackLogoIconComponent";
import BuildingGroupComponent from "components/BuildingGroup/BuildingGroupComponent";
import { Link } from "react-router-dom";
import CreateBuildingForm from "components/CreateBuildingForm/CreateBuildingForm";
import "./building.css";
const IMIDBuildingPage = () => {
  return (
    <>
      <div className="tenant-wrapper">
        <div className="building-form-wrapper">
          <Link to="/buildings">
            <BackLogoIconComponent />
          </Link>
        </div>
        {/* <BuildingGroupComponent /> */}
        <CreateBuildingForm />
      </div>
    </>
  );
};

export default IMIDBuildingPage;
