import React, { useEffect, useState } from "react";
import singleBuildingImage from "../../assets/images/images/single-building.svg";
import multiBuildingImage from "../../assets/images/images/multi-building.svg";
import { Link } from "react-router-dom";
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";

import building from "../../assets/images/imageIcon/buildings-2.svg";
import next from "../../assets/images/imageIcon/next.svg";
import prev from "../../assets/images/imageIcon/prev.svg";

import { getImidServiceUrl } from "utils/api-util";

function BuildingList () {
  const [buildings, setBuildings] = useState([]);
  const [activeTab, setActiveTab] = useState("all");
  const [page, setPage] = useState(0); // Pagination state
  const [loading, setLoading] = useState(false); // Loading state
  const token = window.localStorage.getItem("token");

  const baseUrl = getImidServiceUrl();

  useEffect(() => {
    const url =
      `${baseUrl}/building`;
    const params = new URLSearchParams({
      pageSize: 6,
      page: page, // Use pagination state here
    }).toString();
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };

    setLoading(true); // Start loading
    fetch(`${url}?${params}`, { headers })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        if (Array.isArray(data.items)) {
          setBuildings(data.items);
          console.log(data.items)
        } else {
          setBuildings([]);
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the buildings!", error);
      })
      .finally(() => {
        setLoading(false); // Stop loading
      });
  }, [token, page]); // Fetch data when token or page changes

  const filteredBuildings = Array.isArray(buildings)
    ? buildings.filter((building) => {
      if (activeTab === "all") return true;
      if (activeTab === "active") return building.status === "Active";
      if (activeTab === "inactive") return building.status === "Inactive";
      return false;
    })
    : [];

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    if (page > 0) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <div className="mt-32">
      {!loading && buildings.length > 0 && (
        <div className="tabs-container">
          <div className="tabs">
            <button
              onClick={() => setActiveTab("all")}
              className={`tab ${activeTab === "all" ? "active" : ""}`}
            >
              All Buildings
            </button>
            <button
              disabled
              onClick={() => setActiveTab("active")}
              className={`tab ${activeTab === "active" ? "active" : ""}`}
            >
              Active Buildings
            </button>
            <button
              disabled
              onClick={() => setActiveTab("inactive")}
              className={`tab ${activeTab === "inactive" ? "active" : ""}`}
            >
              Inactive Buildings
            </button>
            <div className="building-button justify-self-end">
              <Link to={"/imidbuilding"}>
                <Button className="add-building-button">
                  <img src={building} className="img-building" alt="building" />
                  Add Building
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}

      {loading ? (
        <div className="flex items-center justify-center min-h-screen ml-56 mb-60">
          <ClipLoader color={"#123abc"} loading={loading} size={50} />
        </div>
      ) : (
        <div className="transactions">
          {filteredBuildings.length > 0 ? (
            <div className="grid grid-cols-3 gap-20 md:grid-cols-2 sm:grid-cols-1 sm:justify-items-center">
              {filteredBuildings.map((building) => (
                <div key={building.id} className="building">
                  <center>
                    <img
                      src={
                        building.numberOfApartments <= 5
                          ? singleBuildingImage
                          : multiBuildingImage
                      }
                      alt="building"
                    />
                  </center>
                  <div className="flex justify-between gap-4">
                    <div className="building-details">
                      <p className="text-left font-bold text-[10px] w-[100%]  pb-1">
                        Building ID
                      </p>
                      <p className="text-left font-bold text-[10px] pb-1">Address</p>
                      <p className="text-left font-bold text-[10px] pb-1">City</p>
                      <p className="text-left font-bold text-[10px] w-[100%] pb-1">
                        Number of Unit
                      </p>
                      <p className="text-left font-bold text-[10px]">Contact </p>
                    </div>
                    <div>
                      <p className="text-left font-bold text-[10px] pb-1">{building.id}</p>
                      <p className="text-left font-bold text-[10px] pb-1 w-[100%]">
                        {building.address}
                      </p>
                      <p className="text-left font-bold pb-1 text-[10px] ">
                        {building.city}
                      </p>
                      <p className="text-left font-bold pb-1 text-[10px] ">
                        {building.numberOfApartments}
                      </p>
                      <p className="text-left pb-1 font-bold text-[10px] ">
                        {building.contactName}
                      </p>
                    </div>
                  </div>
                  <br />
                  <Link
                    to={{
                      pathname: `/building/${building.id}/apartments`,
                    }}
                    state={building}
                  >
                    <center>
                      <div className="view-building-button">
                        <Button>View Details</Button>
                      </div>
                    </center>
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex items-center justify-center min-h-screen ml-56 mb-60">
              <p className="font-bold text-xl">No buildings available.</p>
            </div>
          )}
        </div>
      )}
      <div className="flex flex-row justify-end">
        <img
          className="arrow-tag"
          src={prev}
          alt="previous"
          onClick={handlePreviousPage}
          disabled={page === 0}
        />
        <div className="p-5">{page + 1}</div>
        <img
          className="arrow-tag"
          src={next}
          alt="next"
          onClick={handleNextPage}
        />
      </div>
    </div>
  );
}

export default BuildingList;
