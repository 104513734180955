import React from "react";
import LeftArror from "../../assets/images/imageIcon/img_arrowleft.svg";
const BackLogoIconComponent = () => {
  return (
    <div style={{ position: "relative", top: "43px" }}>
      <div
        style={{
          width: 96,
          height: 30,
          justifyContent: "center",
          alignItems: "center",
          gap: 19,
          display: "inline-flex",
        }}
      >
        <img src={LeftArror} alt="back-logo" />
        <div
          style={{
            color: "#808080",
            fontSize: 18,
            fontFamily: "Montserrat",
            fontWeight: "600",
            lineHeight: 24,
            wordWrap: "break-word",
          }}
        >
          Back
        </div>
      </div>
    </div>
  );
};

export default BackLogoIconComponent;
