import React, { useState } from "react";
import "./tenant.styles.css";
import { useSelector } from "react-redux";
import { Button, Input } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { getAuthUrl, getImidServiceUrl } from 'utils/api-util';
const ResetPasswordTenantComponent = () => {

  const [newPassword, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState('')

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [currentpasswordVisible, setCurrentpasswordVisible] = useState(false)

  const [isSuccessful, setIsSuccessful] = useState(false)

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  const toggleCurrentPasswordVisibility = () => {
    setCurrentpasswordVisible(!currentpasswordVisible)
  }

  const accessToken = localStorage.getItem('accessToken')


  const baseUrl = `${getAuthUrl()}/auth/user-change-password`;

  const dataToServer = {
    currentPassword,
    newPassword,
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&.])[A-Za-z\d@$#!%*?&.]{8,}$/;
    try {
      if (!regex.test(newPassword)) {
        setMsg(
          "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
        );
      }
      else if (!regex.test(confirmPassword)) {
        setMsg(
          "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
        );
      }
      else if (!regex.test(currentPassword)) {
        setMsg(
          "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
        );
      }
      else if (newPassword !== confirmPassword) {
        setMsg('Passwords do not match')
      }
      else {
        setMsg('')
        setIsSuccessful(false)
        setLoading(true)
        const res = await fetch(baseUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify(dataToServer)
        })
        setLoading(false)
        if (res.ok) {
          setIsSuccessful(true)
          setMsg('Password has been changed successfully')
        }
        else {
          setIsSuccessful(false)
          setMsg('Something went wrong')
        }
        const data = await res.json()
        console.log(data)

      }
    }
    catch (err) {
      setLoading(false)
      console.log('err: ', err)
    }
  }

  return (
    <>
      <div className="">
        <div className="auth-header">
          <h2>Change Password</h2>
          <h4>
            Your new password must be different from the previous one
          </h4>

          <form
            onSubmit={handleSubmit}
          >
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[10px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
              className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
              name="password"
              placeholder="********************"
              labelText="Enter current password"
              type={!currentpasswordVisible ? "password" : 'text'}
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              prefix={
                <button
                  type="button"
                  onClick={toggleCurrentPasswordVisibility}
                  className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                >
                  {currentpasswordVisible ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                      />
                    </svg>
                  )}
                </button>
              }
            // ref={passwordRef}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[10px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
              className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
              name="password"
              placeholder="********************"
              labelText="Enter new password"
              type={!passwordVisible ? "password" : 'text'}
              value={newPassword}
              onChange={(e) => setPassword(e.target.value)}
              prefix={
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                >
                  {passwordVisible ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                      />
                    </svg>
                  )}
                </button>
              }
            // ref={passwordRef}
            ></Input>
            <Input
              wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
              className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
              labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
              name="password"
              placeholder="********************"
              labelText="Confirm New Password"
              type={!confirmPasswordVisible ? "password" : 'text'}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              prefix={
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                >
                  {confirmPasswordVisible ? (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6 text-[#0074bb]'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                      />
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                      />
                    </svg>
                  )}
                </button>
              }
            // ref={passwordRef}
            ></Input>

            <Button
              className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[400px]"
            // onClick={handler}
            >
              {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Set New Password"}
            </Button>
            <p className={`mt-2 text-[12px] ${isSuccessful ? 'text-[green] ' : 'text-[red]'}`} >{msg}</p>
          </form>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordTenantComponent;
