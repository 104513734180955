import React, { useState } from 'react';
import './Tab.css'; // Import CSS file for styling
import PersonalInformationTabComponent from 'components/PersonalInformationTabComponent/PersonalInformationTabComponent';
import HouseInformationTabComponent from 'components/HouseInformationTabComponent/HouseInformationTabComponent';
import DeviceDetailsTabComponent from 'components/DeviceDetailsTabComponent/DeviceDetailsTabComponent';
import ResetPasswordTenantComponent from 'components/TenantAuth/ResetPasswordTenant';

const Tab = () => {
  const tabs = [
    { title: 'Personal Information', content: <PersonalInformationTabComponent /> },
    { title: 'Unit Information', content: <HouseInformationTabComponent /> },
    { title: 'Meter Information', content: <DeviceDetailsTabComponent /> },
    { title: 'Reset Password', content: <ResetPasswordTenantComponent /> },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div className="tab-container">
      {/* <h1>Tab Example</h1> */}
      <div className="tabs">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`tab ${index === activeTab ? 'active' : ''}`}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
            {index === activeTab && <hr style={{ backgroundColor: '#0074BB', height: '2px', border: 'none', marginTop: "9px" }} />}
          </div>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tab;
