import React, { useState, useEffect } from 'react';
import AuthBackground from 'components/TenantAuth/AuthBackground';
import LoginForm from 'components/TenantAuth/LoginTenant';

const TenantAuthentication = () => {
  const [showBackground, setShowBackground] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1028) {
        setShowBackground(false);
      } else {
        setShowBackground(true);
      }
    };

    handleResize(); // Call it initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div
      // style={{
      //   height: '100vh',
      //   display: 'grid',
      //   gridTemplateColumns: '1fr 1fr',
      // }}
    >
      {/* {showBackground && <AuthBackground />} */}
      <LoginForm />
    </div>
  );
};

export default TenantAuthentication;
