import React, { useState, useEffect } from "react";
import "./tenant.styles.css";
import { useSelector } from "react-redux";
import { Button, Input, Text } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { getAuthUrl, getImidServiceUrl } from 'utils/api-util';
import { useLocation, useNavigate } from 'react-router-dom'
const UserForgotPassword = () => {

    const location = useLocation();
    const { state } = location
    const userName = state

    const navigate = useNavigate()

    const [newPassword, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [msg, setMsg] = useState('')

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);

    const [isSuccessful, setIsSuccessful] = useState(false)


    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const baseUrl = `${getAuthUrl()}/auth/forgot-password-confirm`;

    const dataToServer = {
        userName,
        newPassword,
        confirmationCode
    }


    const handleSubmit = async (e) => {
        e.preventDefault()
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.])[A-Za-z\d@$!%*?&.]{8,}$/;
        try {
            if (!regex.test(newPassword)) {
                setMsg(
                    "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
                );
            }
            else if (!regex.test(confirmPassword)) {
                setMsg(
                    "Password must be at least 8 characters long, include an uppercase letter, a lowercase letter, a number, and a special character."
                );
            }
            else if (newPassword !== confirmPassword) {
                setMsg('Passwords do not match')
            }
            else {
                setMsg('')
                setIsSuccessful(false)
                setLoading(true)
                console.log(dataToServer)
                const res = await fetch(baseUrl, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(dataToServer)
                })
                setLoading(false)
                console.log(res)
                if (res.ok) {
                    setIsSuccessful(true)
                    navigate('/')
                }
                const data = await res.json()
                console.log(data)


            }
        }
        catch (err) {
            setLoading(false)
            console.log('err: ', err)
        }
    }

    useEffect(() => {
        if (isSuccessful) {
            navigate('/')
        }
    }, [isSuccessful])

    return (
        <div className="bg-gray_50 flex font-montserrat items-center justify-start mx-[auto] w-[100%]">
            <div className="flex flex-col gap-[138px] md:gap-[40px] justify-start w-[100%]">
                {/*<div className="absolute bg-gray_600 h-[17px] inset-x-[0] top-[0] w-[100%]"></div>*/}
                <div className="flex md:flex-col flex-row md:gap-[40px] items-start justify-between  mx-[auto] md:px-[20px] w-[100%]">
                    <div className="bg-white_A700 auth-header border-[1px] border-gray_300 border-solid flex md:flex-1 flex-col items-center justify-end mb-[155px]  p-[46px] sm:px-[20px] md:px-[40px] sm:relative rounded-[10px] shadow-bs1 top-[50px] md:w-[100%] w-[auto]">
                        <h2>Confirm New Password</h2>
                        <Text
                            className="font-medium mt-[18px] text-center text-gray_600 w-[auto]"
                            variant="body3">
                            Enter the confirmation code sent to your email and your new password
                        </Text>

                        <form
                            onSubmit={handleSubmit}
                        >
                            <Input
                                wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                                h-[66px] md:h-[auto] items-center justify-start mt-[10px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
                                className="font-semibold p-[0] 
                                 placeholder:text-gray_600 text-[16px] 
                                 text-gray_600 text-left w-[100%]"
                                labelClass="relative text-sm 
                                text-gray-500 
                                dark:text-gray-400                  
                                peer-focus:dark:text-blue-500"
                                name="password"
                                placeholder="eg: 1234"
                                labelText="Enter confirmation code"
                                type='number'
                                value={confirmationCode}
                                onChange={(e) => setConfirmationCode(e.target.value)}
                            // ref={passwordRef}
                            ></Input>
                            <Input
                                wrapClassName="bg-gray_100 mt-2 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                                h-[66px] md:h-[auto] items-center justify-start mt-[10px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
                                className="font-semibold p-[0] 
                                 placeholder:text-gray_600 text-[16px] 
                                 text-gray_600 text-left w-[100%]"
                                labelClass="relative text-sm 
                                text-gray-500 
                                dark:text-gray-400                  
                                peer-focus:dark:text-blue-500"
                                name="password"
                                placeholder="********************"
                                labelText="Enter new password"
                                type={!passwordVisible ? "password" : 'text'}
                                value={newPassword}
                                onChange={(e) => setPassword(e.target.value)}
                                prefix={
                                    <button
                                        type="button"
                                        onClick={togglePasswordVisibility}
                                        className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                                    >
                                        {passwordVisible ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-6 h-6 text-[#0074bb]'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-6 h-6 text-[#0074bb]'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                                                />
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                                                />
                                            </svg>
                                        )}
                                    </button>
                                }
                            // ref={passwordRef}
                            ></Input>

                            <Input
                                wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                                h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[400px]"
                                className="font-semibold p-[0] 
                                 placeholder:text-gray_600 text-[16px] 
                                 text-gray_600 text-left w-[100%]"
                                labelClass="relative text-sm 
                                text-gray-500 
                                dark:text-gray-400                  
                                peer-focus:dark:text-blue-500"
                                name="password"
                                placeholder="********************"
                                labelText="Confirm New Password"
                                type={!confirmPasswordVisible ? "password" : 'text'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                prefix={
                                    <button
                                        type="button"
                                        onClick={toggleConfirmPasswordVisibility}
                                        className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                                    >
                                        {confirmPasswordVisible ? (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-6 h-6 text-[#0074bb]'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88'
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                xmlns='http://www.w3.org/2000/svg'
                                                fill='none'
                                                viewBox='0 0 24 24'
                                                strokeWidth={1.5}
                                                stroke='currentColor'
                                                className='w-6 h-6 text-[#0074bb]'
                                            >
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z'
                                                />
                                                <path
                                                    strokeLinecap='round'
                                                    strokeLinejoin='round'
                                                    d='M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z'
                                                />
                                            </svg>
                                        )}
                                    </button>
                                }
                            // ref={passwordRef}
                            ></Input>

                            <Button
                                className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[400px]"
                            // onClick={handler}
                            >
                                {loading ? <ClipLoader size={20} color={"#ffffff"} /> : "Confirm New Password"}
                            </Button>
                            <p className={`mt-2 text-[12px] ${isSuccessful ? 'text-[green] ' : 'text-[red]'}`} >{msg}</p>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default UserForgotPassword;
