import AuthBackground from 'components/TenantAuth/AuthBackground'
import ResetPasswordTenantComponent from 'components/TenantAuth/ResetPasswordTenant'
import React from 'react'

const TenantResetPassword = () => {
  return (
    <div
    style={{
        height: "100vh", // Set the height of the container to 100% of the viewport height
        display: "grid",
        gridTemplateColumns: "1fr 1fr"
      }}
    >
        <AuthBackground />
        <ResetPasswordTenantComponent />
    </div>
  )
}

export default TenantResetPassword