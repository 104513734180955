// import React, { useState } from "react";
// import ProfileSettingsSidebarmenu from "components/ProfileSettingsSidebarmenu";
// import Sidebar from "components/Sidebar";
import { FaBars, FaTimes } from "react-icons/fa";
import { Img, Text, Input } from "components";
import { useSelector} from "react-redux"
import ImageSource from "../../assets/images/imageIcon/img_imidicon21.png"

const ProfileSettingsColumnOne = (props) => {
  // const [showMenu, setShowMenu] = useState(true);
  // const handleNav = () => {
  //   setShowMenu((prev) => !prev);
  // };
  const user = useSelector((state) => state.auth.userData);
  return (
    <>
      <nav className={props.className}>
        {/* <div className="flex items-center justify-start md:w-[100%] w-[98%]"> */}
        <header className="flex  md:gap-[20px] items-center justify-between space-x-3 w-[100%] px-2 py-[10px]">
          <div className="flex items-center justify-start pr-[20px]">
            <Img
              src={ImageSource}
              className="h-[40px] w-[100px] min-w-[100px] "
              alt="imidiconTwentyOne"
            /> 
            {/* <Img
              src="images/img_menu.svg"
              className="h-[24px] w-[24px]"
              alt="menu_One"
            /> */}
            <div
              className="cursor-pointer hidden md:flex"
              onClick={props.handleNav}
            >
              {props.showMenu ? <FaBars size={20} /> : <FaTimes size={20} />}
            </div>
          </div>
          <div className="sm:hidden md:flex md:flex-row md:space-x-1 justify-between items-center w-[auto]">
            {/* <div className="text-center md:hidden">
              <Text
                className=" font-bold font-montserrat my-[10px] text-bluegray_900"
                variant="body3"
              >
                Account Settings
              </Text>
            </div> */}

            {/* <div className="bg-gray_51 border-[1px] border-gray_301 border-solid flex sm:flex-1 items-start justify-start md:ml-[0] ml-[138px] md:mt-[0] my-[3px] px-[20px] py-[9px] rounded-[7px] sm:w-[100%] w-[404px]"> */}
            {/* <div className="flex flex-row gap-[9px] items-center justify-start self-stretch w-[auto]"> */}
            {/* <Input
              wrapClassName="bg-gray_51 border-[1px] border-gray_301 border-solid flex items-start justify-start   px-[20px] py-[9px] rounded-[7px] "
              className="flex flex-row gap-[9px] items-center justify-start self-stretch w-[auto]"
              name="search"
              placeholder="Search for assets"
              prefix={
                <Img
                  src="images/img_close_light_blue_800.svg"
                  className="mr-[20px] my-[auto]"
                  alt="user"
                />
              }
            ></Input> */}

            <h1 className="tenant-header">Beta Version</h1>
          </div>
          <div className="flex flex-row space-x-4  items-center justify-start   w-[auto]">
            <Img
              src="images/img_notification.svg"
              className="h-[22px] md:ml-[0]  md:mt-[0] my-[9px] w-[22px]"
              alt="notification"
            />
            <Img
              src={props?.image}
              className="h-[40px] md:h-[auto] object-cover rounded-[7px] w-[40px]"
              alt="rectangle533"
            />
            <div className="flex flex-col gap-[-2px] items-start justify-start self-stretch w-[auto] my-3">
              <Text
                className="font-montserrat font-normal not-italic text-black_900 text-left w-[auto]"
                variant="body5"
              >
                {props?.userEmail}
              </Text>
              <Text
                className="font-bold font-montserrat text-left text-light_blue_800 w-[auto]"
                variant="body3"
              >
                {props?.userRole}
              </Text>
            </div>
          </div>
        </header>
      </nav>
    </>
  );
};

ProfileSettingsColumnOne.defaultProps = {
  userName: "Abdul Ganiu",
  userRole: "Admin Manager",
  userEmail: "user@gmail.com",
  image: "",
};

export default ProfileSettingsColumnOne;
