import React from "react";
import ModalProvider from "react-modal";
import { Img, Text, Button } from "components";

const ProfileSettingsSevenModal = (props) => {
  return (
    <>
      <ModalProvider
        appElement={document.getElementById("root")}
        className="m-[auto] !w-[59%]"
        isOpen={props.isOpened}
        overlayClassName="bg-gray_500_99 fixed flex h-[100%] inset-y-[0] w-[100%]"
        {...props}
      >
        <div className="max-h-[97vh] overflow-y-auto sm:w-[100%] md:w-[100%]">
          <div className="bg-white_A700 border-[1px] border-gray_300 border-solid flex flex-col justify-center p-[39px] md:px-[20px] rounded-[20px] w-[100%]">
            <div className="flex flex-row gap-[19px] items-center justify-center mr-[698px] mt-[18px] self-stretch w-[auto]">
              <Img
                src="images/img_close.svg"
                className="common-pointer h-[30px] w-[30px]"
                onClick={() => {
                  props.closeAction();
                }}
                alt="close"
              />
              <Text
                className="font-semibold text-gray_600 text-left w-[auto]"
                variant="body1"
              >
                Close
              </Text>
            </div>
            <Text
              className="font-black mt-[23px] text-center text-gray_800 w-[auto]"
              as="h3"
              variant="h3"
            >
              Are you Sure ?
            </Text>
            <div className="flex flex-col gap-[43px] items-center justify-start mb-[33px] md:ml-[0] ml-[91px] mr-[78px] mt-[27px] md:w-[100%] w-[79%]">
              <Text
                className="font-medium leading-[24.00px] text-center text-gray_601 sm:w-[100%] w-[98%]"
                variant="body2"
              >
                Are you sure you want to suspend this administrator. By doing
                that all the administrator duties will be put on hold
              </Text>
              <div className="flex sm:flex-col flex-row gap-[103px] sm:gap-[40px] items-start justify-between self-stretch md:w-[100%] w-[auto]">
                <Button
                  className="border-[1px] border-light_blue_800 border-solid cursor-pointer font-extrabold h-[66px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-light_blue_800 w-[263px]"
                  onClick={() => {
                    props.closeAction();
                  }}
                >
                  No, Go Back
                </Button>
                <Button
                  className="bg-light_blue_800 cursor-pointer font-extrabold h-[66px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A700 w-[263px]"
                  onClick={() => {
                    props.handleLogout();
                  }}
                >
                  Yes, Suspend Admin
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalProvider>
    </>
  );
};

export default ProfileSettingsSevenModal;
