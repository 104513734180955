import React, { useState } from 'react'
import { Input, Datepicker } from "components";
import "./Device.styles.css"
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from 'react-redux';

const DeviceDetailsTabComponent = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.userData);

  return (
    <>

      <div>

        <div className='flex justify-between personalDetailsContainer' >
          <div className=' personalInfoBox mb-2' >
            <h3 className='profile-header mb-1'>Your Meter Information</h3>

          </div>
          <div className='w-[60%] personalInfoInputContainer' >
            <div className='personalInfoInputContainer' >


              <div className='flex  justify-between sm:flex-col' >
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                  //   name="Customer Name/Company"
                  labelText='Account Number'
                  // placeholder="Account Number"
                  value={user?.accountNumber}
                />
                <Input
                  wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                  className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                  labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                  //   name="Customer Name/Company"
                  labelText='Profile Status'
                  //   name="Customer Name/Company"
                  // placeholder="Profile Status"
                  value={user?.profileStatus}
                />
              </div>

              <Input
                wrapClassName="bg-gray_100 border-[1px] flex 
                  flex-row font-montserrat h-[66px] md:h-[auto] 
                  items-center justify-start mt-[25px] px-[10px] 
                  py-[5px] rounded-[10px] sm:w-[100%]"
                className="font-semibold p-[0] placeholder:text-gray-600 
                  text-[14px] text-gray-600 text-left w-[100%]"
                //   labelClass="relative text-sm text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Meter Number'
                // placeholder="Meter Number"
                value={user?.meterId}
              />


            </div>
          </div>

        </div>


      </div>

    </>
  )
}

export default DeviceDetailsTabComponent

