import React, { useRef, useState } from "react";
import "./tenant.styles.css";
import { Button, Text } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

function EmailVerification() {
  let [loading, setLoading] = useState(false);
  const inputsRef = useRef([]);
  const [values, setValues] = useState(new Array(4).fill(""));
  let clipData;

  const navigate = useNavigate()

  const handleKeyUp = (event, index) => {
    if (event.code === "Backspace" && hasNoValue(index)) {
      if (index > 0) inputsRef.current[index - 1].focus();
    } else if (event.target.value !== "") {
      index < inputsRef.current.length - 1
        ? inputsRef.current[index + 1].focus()
        : event.target.blur();
    }
    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = event.target.value;
      return newValues;
    });
  };

  const handleInput = (event) => {
    if (event.target.value > 10) {
      event.target.value = event.target.value % 10;
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    clipData = event.clipboardData.getData("text/plain").split("");
    fillData(index);
  };

  const fillData = (index) => {
    for (let i = index; i < inputsRef.current.length; i++) {
      inputsRef.current[i].value = clipData.shift();
    }
  };

  const hasNoValue = (index) => {
    if (values[index] || values[index] === 0) return false;
    return true;
  };

  return (
    <div className="bg-gray_50 flex font-montserrat items-center justify-start mx-[auto] w-[100%]">
      <div className="flex flex-col gap-[138px] md:gap-[40px] justify-start w-[100%]">
        <div className="flex md:flex-col flex-row md:gap-[40px] items-start justify-between max-w-[1069px] mx-[auto] md:px-[20px] w-[100%]">
          <div className="bg-white_A700 border-[1px] border-gray_300 border-solid mb-[155px] md:mt-[0] mt-[100px] p-[46px] sm:px-[20px] md:px-[40px] sm:relative rounded-[10px] shadow-bs1 top-[50px] md:w-[100%] w-[auto]">
            <div onClick={() => navigate(-1)} className='flex cursor-pointer' >
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="vuesax/linear/arrow-left">
                  <g id="arrow-left">
                    <path id="Vector" d="M12.5 16.5999L7.06667 11.1666C6.425 10.5249 6.425 9.4749 7.06667 8.83324L12.5 3.3999" stroke="#4F4F4F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                </g>
              </svg>
              <p className='' >Back</p>
            </div>

            <div className="flex mt-5 md:flex-1 flex-col items-center justify-end">
              <Text
                className="mt-[23px] text-bluegray_900 text-left w-[auto]"
                as="h2"
                variant="h2"
              >
                Verify your Email Address
              </Text>
              <Text
                className="font-medium mt-[18px] text-center text-gray_600 w-[auto]"
                variant="body3"
              >
                Please Enter the 4 Digit Code sent to abdulganiugmail.com
              </Text>
            </div>

            <div className="flex justify-between items-center my-3">
              {[...Array(4)].map((_, index) => (
                <input
                  key={index}
                  ref={(el) => (inputsRef.current[index] = el)}
                  type="number"
                  min="0"
                  max="9"
                  step="1"
                  aria-label={`${index + 1} digit`}
                  onKeyUp={(event) => handleKeyUp(event, index)}
                  onInput={handleInput}
                  onPaste={(event) => handlePaste(event, index)}
                  className="input-box w-[22%] text-light_blue_800 h-[66px] md:h-[auto] rounded-[10px] font-montserrat border-light_blue_800 bg-gray_100 text-center font-bold"
                />
              ))}
            </div>

            <div className="flex items-center my-3 justify-center" >
              <p>Didn’t recieve a code? </p>
              <div className='ml-4 p-2 rounded-[10px] bg-gray_100' ><p className="text-light_blue_800" >Resend in 56s</p></div>
            </div>

            <div className="">
              <Button className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[500px]">
                {loading ? (
                  <ClipLoader size={20} color={"#ffffff"} />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmailVerification;
