import { Button, List } from 'components'
import IMIDCustomerTwoDashboardcard from 'components/IMIDCustomerTwoDashboardcard'
import React, { useEffect, useState } from 'react'
import group from '../../assets/images/imageIcon/img_user_light_blue_800_40x40.svg'
import './index.css'
import searchIcon from '../../assets/images/imageIcon/img_iconly_lightoutline_search.svg'
import attachment from '../../assets/images/imageIcon/img_whatsapp.svg'
import filter from '../../assets/images/imageIcon/img_sort.svg';
import exportIcon from '../../assets/images/imageIcon/img_clock.svg'
import next from "../../assets/images/imageIcon/next.svg";
import prev from "../../assets/images/imageIcon/prev.svg";
import { useNavigate } from 'react-router-dom'
import { getImidServiceUrl } from 'utils/api-util';

const ImidSuperAdminCustomers = () => {

    const navigate = useNavigate()
    const baseUrl = getImidServiceUrl();

    const url = `${baseUrl}/customer`
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [totalActiveCustomers, setTotalActiveCustomers] = useState(0)
    const [totalInactiveCustomers, setTotalInactiveCustomers] = useState(0)
    const [page, setPage] = useState(0); // Pagination state


    const token = window.localStorage.getItem("token")

    const imidCustomerTwoDashboardcardPropList = [
        { totalcustomers: "Total Customers", twenty: totalCustomers },
        { totalcustomers: "Active Customers", twenty: totalActiveCustomers },
        { totalcustomers: "Inactive Customers", twenty: totalInactiveCustomers },

    ];

    // Fetch total number of customers in api
    const fetchData = async () => {
        try {
            const headers = {
                // Add any headers you need here
                'Content-Type': 'application/json',
                // Example header:
                Authorization: `Bearer ${token}`,
            };
            const response = await fetch(url, { headers });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();

            setTotalCustomers(jsonData?.items.length)
            setTotalActiveCustomers(jsonData?.items.filter(item => item.status === "ENABLED").length)
            setTotalInactiveCustomers(jsonData?.items.filter(item => item.status !== "ENABLED").length)
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchData();
    }, []); // Empty array as dependency means this effect runs only once, similar to componentDidMount


    // Fetch customer data from api
    useEffect(() => {

        const params = new URLSearchParams({
            pageSize: 6,
            page: page, // Use pagination state here
        }).toString();
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };

        setLoading(true); // Start loading
        fetch(`${url}?${params}`, { headers })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then((data) => {
                if (Array.isArray(data.items)) {
                    setData(data.items);
                } else {
                    setData([]);
                }
            })
            .catch((error) => {
                console.error("There was an error fetching the Customers!", error);
            })
            .finally(() => {
                setLoading(false); // Stop loading
            });
    }, [token, page]); // Fetch data when token or page changes


    // Handle Pagination
    const handleNextPage = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const handlePreviousPage = () => {
        if (page > 0) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    // Select Tab
    const [isAllCustomersActive, setIsAllCustomersActive] = useState(true)
    const [isActiveCustomersActive, setIsActiveCustomersActive] = useState(false)
    const [isInActiveCustomersActive, setIsInActiveCustomersActive] = useState(false)

    const makeAllCustomersActive = () => {
        setIsAllCustomersActive(true)
        setIsActiveCustomersActive(false)
        setIsInActiveCustomersActive(false)

    }

    const makeActiveCustomersActive = () => {
        setIsAllCustomersActive(false)
        setIsActiveCustomersActive(true)
        setIsInActiveCustomersActive(false)

    }

    const makeInActiveCustomersActive = () => {
        setIsAllCustomersActive(false)
        setIsActiveCustomersActive(false)
        setIsInActiveCustomersActive(true)

    }

    // truncate text
    const truncateString = (text, wordLimit) => {
        return text.split(' ').slice(0, wordLimit).join(' ') + (text.split('').length > wordLimit ? '...' : '')
    }

    return (
        <div className=' w-full mt-5' >
            <List
                className="sm:flex-col flex-row font-montserrat gap-[38px] grid sm:grid-cols-1 mt-4 md:grid-cols-3 grid-cols-5 justify-center ml-[auto] md:mr-[59px] md:mt-[50px]  w-full"
                orientation="horizontal"
            >
                {imidCustomerTwoDashboardcardPropList.map((props, index) => (
                    <React.Fragment key={`IMIDCustomerTwoDashboardcard${index}`}>
                        <IMIDCustomerTwoDashboardcard
                            image={group}
                            className="flex flex-col items-start justify-start self-stretch sm:w-[100%] w-[auto]"
                            {...props}
                        />
                    </React.Fragment>
                ))}
            </List>
            <div className='flex my-5 allCustomersContainer items-center justify-between' >
                <div className='flex allCustomersInnerContainer' >
                    <div onClick={makeAllCustomersActive} className={`mr-5 cursor-pointer  ${isAllCustomersActive ? 'border-b' : 'border-none'} `} >
                        <p className={`${isAllCustomersActive ? 'font-bold' : 'font-regular'} allCustomersText  text-[#808080] `} >All Customers</p>
                    </div>
                    {/* <div onClick={makeActiveCustomersActive} className={`mr-5 cursor-pointer  ${isActiveCustomersActive ? 'border-b' : 'border-none'} `} >
                        <p className={`${isActiveCustomersActive ? 'font-bold' : 'font-regular'}  allCustomersText  text-[#808080] `} >Active Customers</p>
                    </div>
                    <div onClick={makeInActiveCustomersActive} className={`mr-5 cursor-pointer  ${isInActiveCustomersActive ? 'border-b' : 'border-none'} `} >
                        <p className={`${isInActiveCustomersActive ? 'font-bold' : 'font-regular'} allCustomersText  text-[#808080] `} >Inactive Customers</p>
                    </div>*/}
                </div>

                <Button onClick={() => navigate('/imidcustomer/new-customer')} className="bg-light_blue_800 addCustomerBtn cursor-pointer font-extrabold h-[64px] mb-[20px] px-[7px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[150px]">
                    Add Customer
                </Button>
            </div>

            <div className='flex mb-5 justify-between containerFeaures items-center ' >
                <div className='flex bg-[#f7fafd] rounded-md w-[50%] featuresSearchContainer items-center p-1 ' >
                    <img src={searchIcon} alt='icon' />
                    <input disabled type='text' className='text-light_blue_800 placeholder-light_blue_800 outline-none w-full border-none bg-transparent ml-1  ' placeholder='Search' />
                </div>
                <div className='flex moreFeaturesContainer' >
                    <div disabled className='flex mr-2 p-3 bg-[#f7fafd]  w-fit rounded-md items-center ' >
                        <img src={filter} alt='icon' />
                        <p className='ml-2 text-light_blue_800 ' >Filter</p>
                    </div>
                    <div disabled className='flex mr-2 p-3 bg-[#f7fafd]  w-fit rounded-md items-center ' >
                        <img src={attachment} alt='icon' />
                        <p className='ml-2 text-light_blue_800 ' >Attachment</p>
                    </div>
                    <div disabled className='flex p-3 bg-[#f7fafd]  w-fit rounded-md items-center ' >
                        <img src={exportIcon} alt='icon' />
                        <p className='ml-2 text-light_blue_800 ' >Export</p>
                    </div>
                </div>
            </div>
            <div className=' allCustomersTableContainer h-fit w-full' >
                <table className=' w-full block allCustomersTable  my-6'>
                    <thead>
                        <tr>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >S/N</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Company Name</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Contact Address</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Location</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Phone Number</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Email Address</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Status</th>
                            <th className='text-[13px] bg-[#0074bb] text-white text-center  ' >Action</th>
                        </tr>
                    </thead>
                    <tbody  >
                        {
                            data.map((item, id) => {
                                const globalIndex = page * 6 + id + 1;
                                return (
                                    <tr key={item.id} >
                                        <td className=' w-[12.8%] text-center' >{globalIndex}</td>
                                        <td className=' w-[12.8%] text-center' >{item.name}</td>
                                        <td className=' w-[12.8%] text-center ' >{truncateString(item.address, 2)}</td>
                                        <td className=' w-[12.8%] text-center ' >{item.country}</td>
                                        <td className=' w-[12.8%] text-center ' >{item.phoneNumber}</td>
                                        <td className=' w-[12.8%] text-center ' >{item.emailAddress}</td>
                                        <td className=' w-[12.8%] text-center ' >{item.status}</td>
                                        <td className=' w-[12.8%] text-center' >
                                            <div className="header-button h-fit p-2 my-2 mx-auto w-fit ml-auto rounded-md">
                                                <Button >
                                                    View
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>

                {
                    data?.length === 0 &&
                    <div className=' flex justify-center items-center w-full h-[300px]' >
                        <p className='w-[50%] font-bold text-[#BDBDBD] text-center' >No Data</p>
                    </div>
                }
            </div>

            <div className="flex flex-row justify-end">
                <img
                    className="arrow-tag"
                    src={prev}
                    alt="previous"
                    onClick={handlePreviousPage}
                    disabled={page === 0}
                />
                <div className="p-5">{page + 1}</div>
                <img
                    className="arrow-tag"
                    src={next}
                    alt="next"
                    onClick={handleNextPage}
                />
            </div>

        </div>

    )
}

export default ImidSuperAdminCustomers

