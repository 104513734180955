import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import axios from "axios";
import { getImidServiceUrl } from "utils/api-util";
import closeIcon from '../../assets/images/imageIcon/img_close.svg'

const GenerateBillModal = ({ isOpen, selectMonth, onClose, onConfirm, numberOfUnit, buildings }) => {

    const [billingMonth, setBillingMonth] = useState('January')
    const baseUrl = getImidServiceUrl();

    const selectBillingMonth = (month) => {
        setBillingMonth(month)
        selectMonth(month)
    }

    const [loading, setLoading] = useState(false);
    const [err, setError] = useState(null)

    const { id } = useParams();
    if (!isOpen) return null;

    const transformArray = (array) => {
        return array.map(({ apartmentNumber }) => {
            // Retain 'name' and 'city', and add new properties
            return {
                apartmentNumber,  // Original 'apartmentnumber
                buildingId: id,  // New property
                commonAreaPercentage: 0 // Another new property
            };
        });
    };

    const apartmentBillRequests = transformArray(buildings);


    const data = {
        totalConsumption: 0,
        sendInvoice: false,
        billingMonth,
        // tariff: 0,
        apartmentBillRequests
    }

    const token = window.localStorage.getItem("token");
    const handleSubmit = async () => {
        setLoading(true);
        const url =
            `${baseUrl}/bills/generate/bulk`;

        const headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        };

        try {
            const res = await axios.post(url, data, { headers });


            if (res.status === 200) {
                console.log(res)
            } else {
                setError("Error sending Data");

            }
        } catch (err) {
            console.log(err);
            setError(err.message)
        } finally {
            setLoading(false); // Reset loading to false when login action is completed

        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-0 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                <div className='mb-2 cursor-pointer justify-end flex items-center' onClick={onClose} >
                    <img className=' mr-1' src={closeIcon} alt='close' />
                </div>
                <h2 className="text-xl font-extrabold mb-4 text-center text-[#4F4F4F]">
                    Generate bill for the building number: {numberOfUnit}
                </h2>

                <p>Select Billing Month</p>
                <select onChange={e => selectBillingMonth(e.target.value)} className='w-full my-3' >
                    <option value='JANUARY' >January</option>
                    <option value='FEBRUARY' >February</option>
                    <option value='MARCH' >March</option>
                    <option value='APRIL' >April</option>
                    <option value='MAY' >May</option>
                    <option value='JUNE' >June</option>
                    <option value='JULY' >July</option>
                    <option value='AUGUST' >August</option>
                    <option value='SEPTEMBER' >September</option>
                    <option value='OCTOBER' >October</option>
                    <option value='NOVEMBER' >November</option>
                    <option value='DECEMBER' >December</option>
                </select>

                <div className="flex justify-center space-x-4">
                    <button
                        className="px-4 py-2 bg-[#fff] text-[12px] border border-[#0074bb] text-[#0074bb] rounded hover:bg-[#dad5d54e]"
                        onClick={handleSubmit}
                    >
                        Generate bill without common area
                    </button>

                    <button
                        className="px-4 py-2 bg-[#0074bb] text-[12px] text-[#fff] rounded hover:bg-[#0054bb]"

                        onClick={onConfirm}
                    >
                        Generate bill with common area
                    </button>
                </div>
            </div>
        </div>
    );
};

GenerateBillModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
};

export default GenerateBillModal;
