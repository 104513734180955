import React, { useState, useEffect } from 'react';
import "./customerTab.styles.css"
const TabComponent = () => {
  const [tenants, setTenants] = useState([]);
  const [activeTab, setActiveTab] = useState('all');

  useEffect(() => {
    fetchTenants();
  }, []);

  const fetchTenants = async () => {
    // Replace with your actual API endpoint
    const response = await fetch('https://api.example.com/tenants');
    const data = await response.json();
    setTenants(data);
  };

  const filteredTenants = tenants.filter((tenant) => {
    if (activeTab === 'all') return true;
    if (activeTab === 'active') return tenant.status === 'Active';
    if (activeTab === 'inactive') return tenant.status === 'Inactive';
    return false;
  });

  return (
    <div className="container">
      <div className="tabs">
        <button onClick={() => setActiveTab('all')} className={activeTab === 'all' ? 'active' : ''}>All Tenants</button>
        <button disabled onClick={() => setActiveTab('active')} className={activeTab === 'active' ? 'active' : ''}>Active Tenants</button>
        <button disabled onClick={() => setActiveTab('inactive')} className={activeTab === 'inactive' ? 'active' : ''}>Inactive Tenants</button>
      </div>
      <button className="add-tenant">Add Tenant</button>

      <table>
        <thead>
          <tr>
            <th>S/Nk</th>
            <th>Tenants Name</th>
            <th>Apartment Name</th>
            <th>Device No</th>
            <th>Phone Number</th>
            <th>Email Address</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredTenants.map((tenant, index) => (
            <tr key={tenant.id}>
              <td>{index + 1}</td>
              <td>{tenant.name}</td>
              <td>{tenant.apartment}</td>
              <td>{tenant.device}</td>
              <td>{tenant.phone}</td>
              <td>{tenant.email}</td>
              <td>{tenant.status}</td>
              <td><button>View Details</button></td>
            </tr>
          ))}
        </tbody>
      </table>


    </div>
  );
};

export default TabComponent;
