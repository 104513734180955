import React from 'react'
import AuthImage from "../../assets/images/images/Rectangle.png"
import "./tenant.styles.css"
const AuthBackground = () => {
  return (
    <div className='background-img-wrapper'>
        
        {/* <img src={AuthImage} alt='tenant authentication' /> */}
    </div>
  )
}

export default AuthBackground