import React from "react";

import { Text, SelectBox, Img } from "components";
import DownArrow from "../../assets/images/imageIcon/img_arrowdown.svg"
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
const DashbaordTwoAnalysticcard = (props) => {
  console.log("Rendering DashbaordTwoAnalysticcard");
  console.log(props)

  const barData = props?.data
  const barDatax = {
    indexBy: "timeStamp",
    yLegend: "Date/time",
    xLegend: "consumption",
    keys: ["Energy consumed", "amount", 
    // "sandwich", "kebab", "fries", "donut"
  ],
    data: [
      {
        timeStamp: "1 AM",
        "Energy consumed": 63,
        // "Energy consumedColor": "hsl(8, 70%, 50%)",
        amount : 39,
        // amountColor: "hsl(245, 70%, 50%)",
        // sandwich: 47,
        // sandwichColor: "hsl(254, 70%, 50%)",
      },
      {
        timeStamp: "2 AM",
        "Energy consumed": 81,
        // "Energy consumedColor": "hsl(64, 70%, 50%)",
        amount: 18,
        // amountColor: "hsl(30, 70%, 50%)",
        // sandwich: 27,
        // sandwichColor: "hsl(21, 70%, 50%)",
      },
      {
        timeStamp: "3 AM",
        "Energy consumed": 80,
        // "Energy consumedColor": "hsl(118, 70%, 50%)",
        amount: 0,
        // amountColor: "hsl(45, 70%, 50%)",
        // sandwich: 108,
        // sandwichColor: "hsl(298, 70%, 50%)",
      },
      {
        timeStamp: "4 AM",
        "Energy consumed": 63,
        // "Energy consumedColor": "hsl(8, 70%, 50%)",
        amount: 39,
        // amountColor: "hsl(245, 70%, 50%)",
        // sandwich: 47,
        // sandwichColor: "hsl(254, 70%, 50%)",
      },
      {
        timeStamp: "5 AM",
        "Energy consumed": 81,
        // "Energy consumedColor": "hsl(64, 70%, 50%)",
        amount: 18,
        // amountColor: "hsl(30, 70%, 50%)",
        // sandwich: 27,
        // sandwichColor: "hsl(21, 70%, 50%)",
      },
      {
        timeStamp: "6 AM",
        "Energy consumed": 92,
        // "Energy consumedColor": "hsl(118, 70%, 50%)",
        amount: 0,
        // amountColor: "hsl(45, 70%, 50%)",
        // sandwich: 108,
        // sandwichColor: "hsl(298, 70%, 50%)",
      },
      {
        timeStamp: "7 AM",
        "Energy consumed": 92,
        // "Energy consumedColor": "hsl(118, 70%, 50%)",
        amount: 32,
        // amountColor: "hsl(45, 70%, 50%)",
        // sandwich: 108,
        // sandwichColor: "hsl(298, 70%, 50%)",
      },
      {
        timeStamp: "8 AM",
        "Energy consumed": 92,
        // "Energy consumedColor": "hsl(118, 70%, 50%)",
        amount: 49,
        // amountColor: "hsl(45, 70%, 50%)",
        // sandwich: 108,
        // sandwichColor: "hsl(298, 70%, 50%)",
      },
    ],
  };

  // console.log("=========================================================")
  // console.log(barData)
  return (
    <>
      <div className={props.className}>
        <div className="absolute bg-white_A700 border-[1px] border-gray_400 border-solid flex h-[100%]   justify-center m-[auto] p-[16px] rounded-[10px] w-[100%]">
          <div className="flex flex-col items-start justify-start mb-[5px] mt-[8px] md:w-[100%] w-[100%]">
            <div className="flex md:flex-col flex-row md:gap-[20px] items-start justify-start md:w-[100%] w-[100%]">
              <div className="flex items-center justify-start md:ml-[0] ml-[14px] md:w-[100%] w-[100%]">
                <div className="flex flex-col items-start justify-start self-stretch w-[100%]">
                  <Text
                    className="font-medium font-montserrat text-left text-light_blue_800 w-[auto]"
                    variant="body2"
                  >
                    {props?.parameter}
                  </Text>
                  <Text
                    className="font-montserrat font-normal not-italic text-gray_800 text-left w-[auto]"
                    variant="body5"
                  >
                    Device: {props?.deviceId}
                  </Text>
                </div>
              </div>
              <SelectBox
                className="border-[1px] border-light_blue_800 border-solid font-lato font-medium md:ml-[0] ml-[18px] px-[15px] py-[8px] rounded-[5px] text-[12px] text-left text-light_blue_800 md:w-[100%] w-[100%]"
                placeholderClassName="text-light_blue_800"
                name="ParameterSelection"
                placeholder="Parameter"
                isSearchable={false}
                isMulti={false}
                indicator={
                  <Img
                    src={DownArrow}
                    className="h-[16px] w-[16px]"
                    alt="arrow_down"
                  />
                }
                options={props.parameters}
                value={props.selectedParameter}
                onChange={props.onChange}
              ></SelectBox>
              <SelectBox
                className="border-[1px] border-light_blue_800 border-solid font-lato font-medium md:ml-[0]  px-[15px] py-[8px] rounded-[5px] text-[12px] text-left text-light_blue_800 md:w-[100%] w-[100%]"
                placeholderClassName="text-light_blue_800"
                name="Interval"
                placeholder="Interval"
                isSearchable={false}
                isMulti={false}
                indicator={
                  <Img
                    src={DownArrow}
                    className="h-[16px] w-[16px]"
                    alt="arrow_down"
                  />
                }
                selectedParameter={props.selectedInterval}
                options={props.intervals}
                value={props.selectedInterval}
                onChange={props.onIntervalChange}
              ></SelectBox>
            </div>
            {barData.data && (
            <div className="flex flex-col min-h-[80%] items-center justify-start mt-[1px] w-[100%]">
              <ResponsiveBar
                data={barData.data}
                // keys= {["Energy", "amount"]}
                keys={barData.keys}
                indexBy={barData.indexBy}
                margin={{ top: 50, right: 100, bottom: 50, left: 80 }}
                padding={0.2}
                valueScale={{ type: "linear" }}
                indexScale={{ type: "band", round: true }}
                colors={{ scheme: "nivo" }}
                groupMode="grouped"
                defs={[
                  {
                    id: "dots",
                    type: "patternDots",
                    background: "inherit",
                    color: "#38bcb2",
                    size: 4,
                    padding: 1,
                    stagger: true,
                  },
                  {
                    id: "lines",
                    type: "patternLines",
                    background: "inherit",
                    color: "#eed312",
                    rotation: -45,
                    lineWidth: 6,
                    spacing: 5,
                  },
                ]}
                // fill={[
                //   {
                //     match: {
                //       id: "fries",
                //     },
                //     id: "dots",
                //   },
                //   {
                //     match: {
                //       id: "sandwich",
                //     },
                //     id: "lines",
                //   },
                // ]}
                borderColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: barData.yLegend,
                  legendPosition: "middle",
                  legendOffset: 32,
                  truncateTickAt: 0,
                }}
                axisLeft={{
                  tickSize: 5,
                  tickPadding: 5,
                  tickRotation: 0,
                  legend: barData.xLegend,
                  legendPosition: "middle",
                  legendOffset: -40,
                  truncateTickAt: 0,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                  from: "color",
                  modifiers: [["darker", 1.6]],
                }}
                legends={[
                  {
                    dataFrom: "keys",
                    anchor: "bottom-right",
                    direction: "column",
                    justify: false,
                    translateX: 120,
                    translateY: 0,
                    itemsSpacing: 2,
                    itemWidth: 100,
                    itemHeight: 20,
                    itemDirection: "left-to-right",
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                      {
                        on: "hover",
                        style: {
                          itemOpacity: 1,
                        },
                      },
                    ],
                  },
                ]}
                // ariaLabel="Nivo bar chart demo"
                // barAriaLabel={(e) =>
                //   e.id +
                //   ": " +
                //   e.formattedValue +
                //   " in country: " +
                //   e.indexValue
                // }
              />{" "}
            </div> )}
          </div>
        </div>
      </div>
    </>
  );
};

DashbaordTwoAnalysticcard.defaultProps = {
  deviceId: "000300006438",
  parameter: "Total Active Energy",
  scale: "every day",
};

export default DashbaordTwoAnalysticcard;
