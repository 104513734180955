import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/authAction";

const AutoLogout = () => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const automaticLogout = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now();

        // check if the token has expired
        if (
          decodedToken &&
          decodedToken.exp &&
          currentTime >= decodedToken.exp * 1000
        ) {
          try {
            // write the code to send a request to the server to refresh the token
            // update the access token in local storage with the new token
            dispatch(logoutUser());
            navigate("/");
          } catch (error) {
            dispatch(logoutUser());
            navigate("/");
           
          }
        } else {
          dispatch(logoutUser());
          navigate("/");
          
        }
      } catch (error) {
        console.log(error);
        dispatch(logoutUser());
        navigate("/");
        
      }
    } else {
      dispatch(logoutUser());
      navigate("/");
      
    }
  };

  // useEffect(() => {
  //   //set up a timer to periodically check if the token has expired
  //   const autoLogoutTimer = setInterval(automaticLogout, 1000);

  //   // clear the timer when the component unmounts to prevent memory leaks
  //   return () => clearInterval(autoLogoutTimer);
  // }, []);
};

export default AutoLogout;
