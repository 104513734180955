import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";

import { getAuthUrl } from "utils/api-util";

const Redirect = () => {
  const location = useLocation();

  const code = new URLSearchParams(location.search).get("code");
  const realmId = new URLSearchParams(location.search).get("realmId");
  const state = new URLSearchParams(location.search).get("state");

  const authBody = JSON.stringify({
    code,
    realmId,
    state,
  });
  //fetch with no-cors

  const navigate = useNavigate();

  const baseUrl = getAuthUrl();

  const token = window.localStorage.getItem("token");

  useEffect(() => {
    const resp = fetch(`${baseUrl}/auth/quickbook/callback`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: authBody,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .catch((error) => {
        console.log("auth error", error);
      });

    let respose;
    resp.then((r) => {
      console.log("response: ", r);
      respose = r;
      if (respose && respose.success) {
        localStorage.setItem("QBtoken", r.accessToken);
        localStorage.setItem("QBrefreshToken", r.refreshToken);
        localStorage.setItem("QBtokenExpiresIn", r.expiresInSecs);
        navigate("/admin-dashboard");
      } else {
        console.log("error: ", respose);
      }
    });

  });
};

export default Redirect;
