import BuildingInfoComponent from 'components/BuildingInfoComponent/BuildingInfoComponent'
import React from 'react'

const BuildingInfo = () => {
  return (
    <div>
        <BuildingInfoComponent />
    </div>
  )
}

export default BuildingInfo