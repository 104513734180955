import React from "react";
import PropTypes from "prop-types";
import successImage from '../../assets/images/imageIcon/img_successful_green_400_212x237.svg'
import closeIcon from '../../assets/images/imageIcon/img_close.svg'
import { useNavigate } from "react-router-dom";

const AllocateCommonAreaSuccess = ({ isOpen, onClose }) => {


    const navigate = useNavigate()

    const closeSuccess = () => {
        onClose()
        navigate('/buildings/customer')
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                <div className='mb-2 ' >
                    <img className='cursor-pointer' onClick={closeSuccess} src={closeIcon} alt='close' />
                </div>
                <div className="flex w-full justify-center" >
                    <img src={successImage} alt='success' />
                </div>

                <h2 className="text-xl font-extrabold my-2 text-center text-[#46AB55]">
                    Common area allocated successfully
                </h2>
            </div>
        </div>
    );
};

AllocateCommonAreaSuccess.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,

};

export default AllocateCommonAreaSuccess;
