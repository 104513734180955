import React from "react";
import PropTypes from "prop-types";

const ConfirmLogoutModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-extrabold mb-4 text-center text-[#4F4F4F]">
          Confirm Logout
        </h2>
        <p className="mb-6 font-semibold text-center text-[#828282]">
          Are you sure you want to log out?
        </p>
        <div className="flex justify-center space-x-4">
          <button
            className="px-4 py-2 bg-[#fff] border border-[#0074bb] text-[#0074bb] rounded hover:bg-[#dad5d54e]"
            onClick={onClose}
          >
            No
          </button>

          <button
            className="px-4 py-2 bg-[#0074bb] text-[#fff] rounded hover:bg-[#0054bb]"
            
            onClick={onConfirm}
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

ConfirmLogoutModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfirmLogoutModal;
