import React from 'react'
import { jwtDecode } from 'jwt-decode'

const CheckTokenExpiration = () => {
  const expirationTime = localStorage.getItem('tokenExpiration');
  if (expirationTime && new Date().getTime() > expirationTime) {
    return true;
  }
  return false;
};

export default CheckTokenExpiration