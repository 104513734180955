import React from "react"
import BackLogoIconComponent from "components/BackLogoIcon/BackLogoIconComponent"
import BuildingGroupComponent from "components/BuildingGroup/BuildingGroupComponent"
import CreateBuildingForm from "components/CreateBuildingForm/CreateBuildingForm"
import "./propertyManger.styles.css"
import CreatePropertyManagerForm from "components/CreatePropertyManagerForm/PropertyManagerForm"
import PropertyManagerGroupComponent from "components/PropertyManagerGroup/PropertyManagerGroup"
const IMIDPropertyManager = () => {
    return (
        <>
        <div className="property-wrapper">
            <BackLogoIconComponent />
           {/* <PropertyManagerGroupComponent /> */}
           <CreatePropertyManagerForm />
        </div>
        </>
    )
}

export default IMIDPropertyManager