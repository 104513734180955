import React, { useEffect, useState } from "react";
import AssetsTab from "components/BuildingInfoTab/AssetsTab";
import ViewBuildingInformationTab from "components/BuildingInfoTab/ViewBuildingInformationTab";
import { useParams, Link, useLocation } from "react-router-dom";

const BuildingInfoComponent = () => {
  const { id } = useParams();
  const [buildings, setBuildings] = useState([]);
  const token = window.localStorage.getItem("token");

  const { state } = useLocation();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      name: "View Building Information",
      content: <ViewBuildingInformationTab />,
    },
    { name: "Assets", content: <AssetsTab /> },
    { name: "Add Unit", content: "This is the content of Tab 3" },
  ];

  return (
    <div className="w-full max-w-md mx-auto mt-[7rem]">
      <div className="flex border-b border-gray-200">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`py-2 px-4 border-b-2 ${
              activeTab === index
                ? "border-blue-500 text-blue-500"
                : "border-transparent text-gray-500"
            } focus:outline-none`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="p-4">
        {tabs.map((tab, index) => (
          <div
            key={index}
            className={`transition-opacity duration-300 ${
              activeTab === index ? "opacity-100" : "opacity-0 absolute"
            }`}
          >
            {tab.content}
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuildingInfoComponent;
