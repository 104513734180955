import React, { useState } from 'react'
import BackLogoIconComponent from "../../components/BackLogoIcon/BackLogoIconComponent";
import { Link } from 'react-router-dom';
import './index.css'

const CustomerBuildingInfo = () => {

    const [isAssetsActive, setIsAssetsActive] = useState(false)
    const [isBuildingInfoActive, setIsBuildingInfoActive] = useState(true)

    const makeAssetsActive = () => {
        setIsAssetsActive(true)
        setIsBuildingInfoActive(false)
    }

    const makeBuildingInformationActive = () => {
        setIsAssetsActive(false)
        setIsBuildingInfoActive(true)
    }

    return (
        <div>
            <div className="">
                <Link to="/admin-dashboard">
                    <BackLogoIconComponent />
                </Link>
            </div>

            <div className='mt-14' >
                <div className='flex items-center' >
                    <div onClick={makeBuildingInformationActive} className={` ${isBuildingInfoActive && 'border-b   border-light_blue_800'} mr-3 cursor-pointer  py-1`} >
                        <p className={`${isBuildingInfoActive ? 'text-light_blue_800 font-bold' : 'text-black'}`} >Building Information</p>
                    </div>
                    <div onClick={makeAssetsActive} className={` ${isAssetsActive && 'border-b  border-light_blue_800'} cursor-pointer py-1`} >
                        <p className={`${isAssetsActive ? 'text-light_blue_800 font-bold' : 'text-black'}`} >Assets</p>
                    </div>
                </div>
            </div>
            <div className=' flex-col mb-5'>

                {isBuildingInfoActive && <div className='mt-6 flex buildingInfoContainer' >
                    <div className='bg-white w-[300px] buildingInfoBox rounded-[10px] px-5 py-6 mr-4' >
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Building Name</h3>
                            <p className='text-[14px]' >Joy Plaza</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Building ID</h3>
                            <p className='text-[14px]' >2</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Number of Unit</h3>
                            <p className='text-[14px]' >167 Willet Street</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Address</h3>
                            <p className='text-[14px]' >Toronto</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >City</h3>
                            <p className='text-[14px]' >Grace</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' > zip code/postal code</h3>
                            <p className='text-[14px]' >1000112</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Province/state</h3>
                            <p className='text-[14px]' >Toronto</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className='font-bold text-[14px]' >Country</h3>
                            <p className='text-[14px]' >Canada</p>
                        </div>

                    </div>

                    <div className='bg-white w-[300px] assetsInfoBox rounded-[10px] h-fit px-5 py-6 mr-4' >
                        <h2 className='font-bold text-[14px] mb-3' >Building Contact Person</h2>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >Name</h3>
                            <p className='text-[14px]' >Grace</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >Email</h3>
                            <p className='text-[14px]' >Grace@gmail.com</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >Phone Number</h3>
                            <p className='text-[14px]' >01-11223-11223-00</p>
                        </div>


                    </div>
                </div>}


                {isAssetsActive && <div className='mt-6 buildingInfoContainer flex' >
                    <div className='bg-white w-[300px] buildingInfoBox rounded-[10px] h-fit px-5 py-6 mr-4' >
                        <h2 className='font-bold text-[14px] mb-3' >Meter Information</h2>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >Meter Account Number</h3>
                            <p className='text-[14px]' >1222222121112</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >Meter  Number</h3>
                            <p className='text-[14px]' >00000000000</p>
                        </div>
                    </div>

                    <div className='bg-white w-[300px] rounded-[10px] assetsInfoBox h-fit px-5 py-6 mr-4' >
                        <h2 className='font-bold text-[14px] mb-3' >IMID Information</h2>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >IMID Number</h3>
                            <p className='text-[14px]' >1222222121112</p>
                        </div>
                        <div className='flex justify-between items-center mb-2' >
                            <h3 className=' text-[14px]' >IMID MAC Number</h3>
                            <p className='text-[14px]' >00000000000</p>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default CustomerBuildingInfo