import React, { useState } from "react";
import { Img, Text } from "components";
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from "react-redux";

const LandlordProfileComponent = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.userData);

  return (
    <>
      <div>
        <div>
          <div className=" mt-5 personalDetailsContainer">
            <div className="personalInfoBox mb-2">
              <h3 className="profile-header mb-1">Personal Information</h3>
            </div>

            <div className="w-[60%] personalInfoInputContainer grid grid-cols-2 md:grid-cols-1">
              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">Name:</label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px] ">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.name}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">Email:</label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.emailAddress}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">Phone:</label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.phoneNumber}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">
                    Address:
                  </label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.address}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">City:</label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.city}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">
                    Country:
                  </label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.country}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">
                    Postal&nbsp;Code:
                  </label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.postalCode}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">
                    Profile&nbsp;Status:
                  </label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.profileStatus}
                    </Text>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center w-[48%] sm:w-[100%]">
                  <label className="mb-3 text-black font-bold">
                    Account&nbsp;Number:
                  </label>
                  <div className="font-montserrat mt-6 md:mb-9 h-[66px] md:h-[auto] px-[10px] py-[5px]  ml-2">
                    <Text className="font-semibold personInfoInput text-[18px] text-black">
                      {user.accountNumber}
                    </Text>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandlordProfileComponent;
