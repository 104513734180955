
import {extractHour, getLocalDateFromUTC} from "./data-utils";

const DAILY_EIGHT_POINTS_DATA = {
    labels: ['3 AM', '6 AM', '9 AM', '12 PM', '3 PM','6 PM', '9 PM', '12 AM'],
    keys: [3,6,9,12,15,18,21,0]
}

const DAILY_12_POINTS_DATA = {
  labels: ['12 AM', '1 AM', '2 AM','3 AM', '4 AM', '5 AM', '6 AM', '7 AM', '8 AM', '9 AM', '10 AM', '11 AM', '12 PM', '1 PM', '2 PM', '3 PM', '4 PM', '5 PM', '6 PM', '7 PM', '8 PM', '9 PM', '10 PM', '11 PM', '12 AM'],
  keys: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,0]
}

const ENERGY_DATA_POINTS = {
  eight : DAILY_EIGHT_POINTS_DATA,
  twelve: DAILY_12_POINTS_DATA
}

const getEnergyChartData = (data) => {
  console.log(data)
  const d = JSON.parse(data.records);
  // console.log("json record")
  // console.log(d)
  const records =  d.historicalRecords[data.parameter].billingValuesList;
  console.log(records)

  let previous = records[0].value;
  if (data.chartType === 'daily'){
    let firstRecord = false;
    const labels = ENERGY_DATA_POINTS[data.dataPoints]
    const cumulativeEnergy = records[records.length - 1].value;
    const startEnergy = records[0].value;
    const filteredRecordss = records.filter(record => {
        console.log('time: ' + record.timestamp)
        const formatedTime = getLocalDateFromUTC(record.timestamp);
        const time = extractHour(formatedTime);
        console.log('formated time: ' + formatedTime.toLocaleString({dateStyle:"short"}))
        console.log('hour: ' + time)
        // const time = extractHour(record.timestamp);
        if (time === '12 AM' && !firstRecord) {
          firstRecord = true;
          return false;
        } else {
          const valid = labels.labels.includes(time);
          console.log("valid: " + valid)
          return valid;
        }
    }); 

    console.log('normalized data')
    console.log(filteredRecordss)
    const filteredRecords = filteredRecordss.map(item => {
      const consumedEnergy = item.value - previous;
      previous = item.value;
      const formatedTime = getLocalDateFromUTC(item.timestamp);
      const hour = extractHour(formatedTime);
      const amount = consumedEnergy * data.tariff;
      return {
        timeStamp: hour,
        Energy : consumedEnergy.toFixed(2),
        amount : amount.toFixed(2),
        TotalEnergy : item.value,
      }
    })

    const energyUsed = cumulativeEnergy - startEnergy
    const totalAmount = energyUsed * data.tariff;
    return {
      indexBy: "timeStamp",
      yLegend: "Date/time",
      xLegend: "Consumption (kWh)/Amount (C$)",
      keys: ["Energy", "amount"], 
      cumulativeEnergy,
      energyUsed: energyUsed.toFixed(2),
      amount: totalAmount.toFixed(2),
      data: filteredRecords
    }
  }

}

export { getEnergyChartData };
