import React from 'react'
import "./tenantProfile.styles.css"
import Tab from 'components/ProfileTab/ProfileTab'

const TenantProfileSettingsComponent = () => {
  return (
    <>
      <div className='tenanat-settings-wrapper '>
        <div className="settings-header mb-3">
          <h1>Profile</h1>
          {/*<p className='text-[12px] text-[#808080] font-normal' >You are saving energy today, welldone💡 👍🏻</p>*/}
        </div>
        {/* <div className='mini-header'>
            <h3>You are saving energy today, welldone 💡👍</h3>
        </div> */}
        <div>
          <Tab />
        </div>
      </div>
    </>
  )
}

export default TenantProfileSettingsComponent