import React from "react";

import { Img, Text, Button, FloatingInput } from "components";
const IMIDDataPage = () => {
  return (
    <>
      <div className="bg-gray_50 font-montserrat h-[100%] mx-[auto] pb-[82px] relative w-[100%]">
        <div className="flex flex-col h-[100%] items-start justify-start ml-[auto] mr-[88px] my-[auto] md:px-[20px] w-[75%]">
          <div className="flex sm:flex-col flex-row md:gap-[40px] items-start justify-between mt-[43px] w-[100%]">
            <Button className="bg-light_blue_800 cursor-pointer font-extrabold h-[64px] mb-[116px] px-[20px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[210px]">
              IMID Data
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default IMIDDataPage;
