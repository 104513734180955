import AuthBackground from 'components/TenantAuth/AuthBackground'
import EmailVerificationCompoenent from 'components/TenantAuth/EmailVerificationComponent'
import React from 'react'

const TenantEmailVerification = () => {
  return (
    <div
    >
      {/*<AuthBackground />*/}
      <EmailVerificationCompoenent />
    </div>
  )
}

export default TenantEmailVerification