import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import ImageSource from "../../assets/images/imageIcon/img_imidicon21.png"
import { Img, Text, Button, Input } from "components";
import { loginUser } from "../../Redux/authAction";
import Flower from "../../assets/images/imageIcon/flower.f75d2b2e 1.svg"
const LoginForm = () => {
  const dispatch = useDispatch();
  const [userName, setuserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  let [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(loginUser({ userName, password }));
      // If login successful, navigate to /imidcustomer
      navigate("/imidtenant");
    } catch (error) {
      setError("Login failed. Please check your credentials.");
    } finally {
      setLoading(false); // Reset loading to false when login action is completed
    }
  };

  return (
    <>
      <div className="bg-gray_50 flex font-montserrat items-center justify-start mx-[auto] w-[100%]">
        <div className="flex flex-col gap-[138px] md:gap-[40px] justify-start w-[100%]">
          <div className="absolute bg-gray_600 h-[17px] inset-x-[0] top-[0] w-[100%]"></div>
          <div className="flex md:flex-col flex-row md:gap-[40px] items-start justify-between max-w-[1069px] mx-[auto] md:px-[20px] w-[100%]">
            <div className="bg-white_A700 border-[1px] border-gray_300 border-solid flex md:flex-1 flex-col items-center justify-end mb-[155px] md:mt-[0] mt-[100px] p-[46px] sm:px-[20px] md:px-[40px] sm:relative rounded-[10px] shadow-bs1 top-[50px] md:w-[100%] w-[auto]">
            <img
                src={ImageSource}
                className="h-[42px] mt-[41px] w-[auto]"
                alt="imidicon211"
              />
              <Text
                className="mt-[23px] text-bluegray_900 text-left w-[auto]"
                as="h2"
                variant="h2"
              >
                Welcome Back!
              </Text>
              <Text
                className="font-medium mt-[18px] text-center text-gray_600 w-[auto]"
                variant="body3"
              >
                Login back into your account and let’s get started for the day
              </Text>
              <div className="flex flex-col items-center justify-center mt-[48px] w-[]">
                <form onSubmit={handleSubmit}>
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[577px]"
                    className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
                    labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
                    name="userName"
                    type="text"
                    placeholder="Username"
                    // labelText="Username"
                    value={userName}
                    onChange={(e) => setuserName(e.target.value)}
                    prefix={
                      <Img
                        src="images/img_user.svg"
                        className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                        alt="eye"
                      />
                    }
                    // ref={userNameRef}
                  ></Input>
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] border-light_blue_800 border-solid flex flex-row font-montserrat 
                  h-[66px] md:h-[auto] items-center justify-start mt-[25px] px-[20px] py-[11px] rounded-[10px] sm:w-[100%] w-[577px]"
                    className="font-semibold p-[0] 
                  placeholder:text-gray_600 text-[16px] 
                  text-gray_600 text-left w-[100%]"
                    labelClass="relative text-sm 
                  text-gray-500 
                  dark:text-gray-400                  
                    peer-focus:dark:text-blue-500"
                    name="password"
                    placeholder="password"
                    // labelText="Password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    prefix={
                      <Img
                        src="images/img_checkmark.svg"
                        className="mr-[5px] my-[auto] h-[24px] w-[24px]"
                        alt="eye"
                      />
                    }
                    // ref={passwordRef}
                  ></Input>
                  {/* <div className="flex justify-end">
                    <Text
                      className="font-gtwalsheimpro font-light 
                   mt-[27px] 
                  text-[16px] text-gray_800 text-left 
                  w-[100%]"
                      variant="body4"
                    >
                      Forgot Password?{" "}
                      <Link className="font-bold" to={"/forgetpassword"}>
                        Reset
                      </Link>
                    </Text>
                  </div> */}
                  <Button
                    className="bg-light_blue_800 cursor-pointer font-extrabold font-montserrat h-[66px] sm:h-[] sm:ml-[] mt-[23px] sm:mt-[] sm:pl-[19px] px-[19px] py-[10px] rounded-[10px] text-[16px] text-center text-white_A701 sm:w-[100%] w-[577px]"
                    // onClick={handler}
                  >
                    {loading ? (
                      <ClipLoader size={20} color={"#ffffff"} />
                    ) : (
                      "Login"
                    )}
                  </Button>
                  {/* <div className="flex justify-end">
                    <div className="mr-[0%]"></div>
                    <Text
                      className="font-gtwalsheimpro font-light 
                   mt-[27px] 
                  text-[16px] text-gray_800 text-left 
                  mr-0"
                      variant="body4"
                    >
                      Don't have an account?{" "}
                      <Link className="font-bold" to={"/createaccount"}>
                        Create one
                      </Link>
                    </Text>
                  </div> */}
                </form>
              </div>
            </div>
            <Img
              src={Flower}
              className="bottom-[0] sm:fixed h-[210px] inset-x-[0] sm:left-[20px] md:mt-[0] mt-[635px] relative top-[50px] sm:top-[] w-[auto]"
              alt="flower"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
