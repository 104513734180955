import TenantChartComponent from 'components/TenantChartComponent/TenantChartComponent'
import React from 'react'

const TenantChart = () => {
  return (
    <div>
        <TenantChartComponent />
    </div>
  )
}

export default TenantChart