import React from 'react'
import LandlordTab from './LandlordTab'

const LandlordProfileSettingsComponent = () => {
    return (
        <>
        <br />
        <br />
          <div className='tenanat-settings-wrapper '>
            <div className="settings-header">
              <h1>Profile</h1>
              {/*<p className='text-[12px] text-[#808080] font-normal' >You are saving energy today, welldone💡 👍🏻</p>*/}
            </div>
            {/* <div className='mini-header'>
                <h3>You are saving energy today, welldone 💡👍</h3>
            </div> */}
            <div>
              <LandlordTab />
            </div>
          </div>
        </>
      )
}

export default LandlordProfileSettingsComponent