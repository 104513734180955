import React, { useState } from "react";
import { useSelector } from "react-redux"
import { Button, Input } from "components";
import { TextArea } from "components/TextArea";
const HelpSupportPage = () => {


  const user = useSelector((state) => state.auth.userData);

  const [isSupportActive, setIsSupportActive] = useState(true)

  const makeSupportActive = () => {
    setIsSupportActive(true)
  }

  const makeFeedbackActive = () => {
    setIsSupportActive(false)
  }

  console.log(user)

  return (
    <>
      <div className="bg-gray_50 font-montserrat h-[100%] mt-3 mx-[auto] w-[100%]">
        <div className="h-[100%] w-[100%]">
          <div className=" md:gap-[40px] mt-[43px] w-[100%]">
            <div className='mb-4 w-[50%] md:w-[100%]' >
              <h1 className='text-[#808080] mb-3 font-bold text-[20px]' >Support</h1>
              <p className='text-[15px] text-[#808080]  sm:text-[13px]' >How can we help you ?? Enter your concerns ao we can support you </p>
            </div>
            <div className='flex mb-6 border-b-2 w-[50%] p-0 border-white ' >
              <div onClick={makeSupportActive} className={`mr-5 pb-2 cursor-pointer w-[100px] ${isSupportActive ? ' border-b  border-light_blue_800' : ' border-none'} `} >
                <p className={` ${isSupportActive ? 'font-bold text-light_blue_800 ' : 'text-[#808080] font-normal'} `} >Support</p>
              </div>
              <div onClick={makeFeedbackActive} className={` pb-2 cursor-pointer w-[100px] ${!isSupportActive ? ' border-b  border-light_blue_800' : ' border-none'} `} >
                <p className={` ${!isSupportActive ? 'font-bold text-light_blue_800 ' : 'text-[#808080] font-normal'} `} >Feedback</p>
              </div>
            </div>

            {isSupportActive ?
              <div>
                <div className='flex w-full flex-col' >
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox mb-4 sm:my-0
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] md:w-[100%] w-[50%]"
                    className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] w-[100%] text-gray-600 text-left "
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Full Name'
                    // placeholder="Email Address"
                    value={user?.name}
                  />
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] sm:my-4
                    py-[5px] rounded-[10px] sm:w-[100%] md:w-[100%] w-[50%] mb-4 "
                    className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 sm:my-0
                    text-[14px] text-gray-600 text-left"
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Email Address'
                    //   name="Customer Name/Company"
                    // placeholder="Phone Number"
                    value={user?.emailAddress}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <TextArea
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex  pt-[5px]
                    flex-row font-montserrat h-fit md:h-[auto] sm:my-0
                     personInfoInputBox justify-start px-[10px] 
                     rounded-[10px] sm:w-[100%] w-[50%] md:w-[100%] "
                    className="font-semibold w-full h-[120px] personInfoInput placeholder:text-gray-600 px-0
                    text-[14px] text-gray-600 text-left"
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Enter Support'
                  //   name="Customer Name/Company"
                  // placeholder="Phone Number"
                  // value={user?.phoneNumber}
                  />
                </div>

                <div className='flex justify-end mt-6 w-[50%] md:w-[100%]' >
                  <Button className="bg-light_blue_800 self-end cursor-pointer font-extrabold h-[64px] mb-[20px] px-[20px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[210px]">
                    Send Support
                  </Button>
                </div>
              </div>
              :
              <div>
                <div className='flex w-full flex-col' >
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox mb-4 sm:my-0
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] md:w-[100%] w-[50%]"
                    className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] w-[100%] text-gray-600 text-left "
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Full Name'
                    // placeholder="Email Address"
                    value={user?.name}
                  />
                  <Input
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] sm:my-4
                    py-[5px] rounded-[10px] sm:w-[100%] md:w-[100%] w-[50%] mb-4 "
                    className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 sm:my-0
                    text-[14px] text-gray-600 text-left"
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Email Address'
                    //   name="Customer Name/Company"
                    // placeholder="Phone Number"
                    value={user?.emailAddress}
                  />
                </div>
                <div className="flex w-full flex-col">
                  <TextArea
                    wrapClassName="bg-gray_100 border-[1px] 
                     flex  pt-[5px]
                    flex-row font-montserrat h-fit md:h-[auto] sm:my-0
                     personInfoInputBox justify-start px-[10px] 
                     rounded-[10px] sm:w-[100%] w-[50%] md:w-[100%] "
                    className="font-semibold w-full h-[120px] personInfoInput placeholder:text-gray-600 px-0
                    text-[14px] text-gray-600 text-left"
                    labelClass="relative text-[10px] text-gray-500 block dark:text-gray-400 peer-focus:dark:text-blue-500"
                    //   name="Customer Name/Company"
                    labelText='Enter Feedback'
                  //   name="Customer Name/Company"
                  // placeholder="Phone Number"
                  // value={user?.phoneNumber}
                  />
                </div>

                <div className='flex justify-end mt-6 w-[50%] md:w-[100%]' >
                  <Button className="bg-light_blue_800 self-end cursor-pointer font-extrabold h-[64px] mb-[20px] px-[20px] sm:px-[15px] sm:py-[5px] py-[10px] rounded-[10px] text-[14px] text-center text-white_A700 w-[210px]">
                    Send Feedback
                  </Button>
                </div>
              </div>

            }

          </div>
        </div>
      </div>
    </>
  );
};

export default HelpSupportPage;
