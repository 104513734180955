import TenantProfileSettingsComponent from 'components/TenantProfileSettingsComponent/TenantProfileSettingsComponent'
import React from 'react'

const TenantProfileSettings = () => {
  return (
    <div>
      <TenantProfileSettingsComponent />
    </div>
  )
}

export default TenantProfileSettings