import React, { useState } from 'react'
import { Input } from 'components'
import { Button } from "components";
import ClipLoader from "react-spinners/ClipLoader";
import { useSelector } from 'react-redux';

const HouseInformationTabComponent = () => {
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.auth.userData);

  return (
    <>
      <div div className='flex justify-between mt-5 personalDetailsContainer' >
        <div className=' personalInfoBox mb-2' >
          <h3 className='profile-header'>Unit Information</h3>
          <p className='profile-sub-header'>Update your details here</p>
        </div>
        <div className='w-[60%] personalInfoInputContainer' >
          <div className='personalInfoInputContainer' >
            <div className='flex justify-between sm:flex-col' >
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] mb-4 rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Contact Phone Number'
                // placeholder="Contact Phone Number"
                value={user?.buildingContactPhoneNumber}
              />
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Apartment Number'
                //   name="Customer Name/Company"
                placeholder="Apartment Number"
                value={user?.apartmentNumber}
              />
            </div>
            <div className='flex justify-between sm:flex-col' >
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox mb-4
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Address'
                // placeholder="Address"
                value={user?.address}
              />
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox mb-4
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative block text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='City'
                // placeholder="Toronto"
                value={user?.city}
              />
            </div>
            <div className='flex justify-between personalBoxInputContainer sm:flex-col' >
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Province/State'
                // placeholder="Province/State"
                value={user?.province}
              />
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Postal/Zip Code'
                //   name="Customer Name/Company"
                // placeholder="Postal/Zip Code"
                value={user?.postalCode}
              />
            </div>
            <div className='flex justify-between sm:flex-col' >
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Country'
                // placeholder="Canada"
                value={user?.country}
              />
              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex personInfoInputBox
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[48%]"
                className="font-semibold personInfoInput p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left "
                labelClass="relative block text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Contact Name'
                // placeholder="Contact Name"
                value={user?.buildingContactName}
              />
            </div>

            <div className='flex justify-between mt-4 sm:flex-col' >

              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[100%]"
                className="font-semibold personInfoInput w-[90%] p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Apartment Number'
                //   name="Customer Name/Company"
                placeholder="Apartment Number"
                value={user?.apartmentNumber}
              />
            </div>
            <div className='flex justify-between mt-4 sm:flex-col' >

              <Input
                wrapClassName="bg-gray_100 border-[1px] 
                     flex 
                    flex-row font-montserrat h-[66px] md:h-[auto] 
                    items-center personInfoInputBox justify-start px-[10px] 
                    py-[5px] rounded-[10px] sm:w-[100%] w-[100%]"
                className="font-semibold personInfoInput w-[90%] p-[0] placeholder:text-gray-600 
                    text-[14px] text-gray-600 text-left"
                labelClass="relative text-[10px] block text-gray-500 dark:text-gray-400 peer-focus:dark:text-blue-500"
                //   name="Customer Name/Company"
                labelText='Contact Email'
                //   name="Customer Name/Company"
                // placeholder="Contact Email"
                value={user?.buildingContactEmailAddress}
              />
            </div>

          </div>
        </div>

      </div >
    </>
  )
}

export default HouseInformationTabComponent


