import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import cartReducer from "./cartSlice"
// const middleware = [
//     ...getDefaultMiddleware(),// Add your middleware
//   ];

const persistConfig = {
  key: 'root',
  storage,
  // blacklist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
  reducer: {
    auth: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});

export const persistor = persistStore(store);